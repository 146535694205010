@use "../../../scss/colors";
@use "../../../scss/break-points";

.innovateSection {
    display: flex;
    padding: 4% 2%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 35px;

    @media screen and (max-width: 576px) {
        padding: 50px 2%;
        gap: 50px;
    }

    @media screen and (min-width: 1024px) {
        padding: 110px 10px;
        gap: 55px;
    }

    @media screen and (min-width: 769px) {
        padding: 6% 5%;
        gap: 55px;
    }
}

.left {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    text-align: center;
    justify-content: center;
    width: 90%;

    @media screen and (min-width: 1024px) {
        gap: 35px;
        width: 100%;
    }
}

.h1 {
    font-weight: 700;
    font-size: 35px;
    line-height: 34px;
    margin-bottom: 15px;
    text-align: center;
    font-family: Raleway;
    line-height: normal;

    &.light {
        color: colors.$textColorLight;
    }

    &.dark {
        color: #fff;
    }

    @media screen and (max-width: 576px) {
        margin-bottom: 0px;
        font-size: 34px;
        line-height: 54px;
    }

    @media screen and (max-width: 405px) {
        font-size: 26px;
        line-height: normal;
    }

    @media screen and (min-width: 1024px) {
        font-size: 60px;
    }

    @media screen and (min-width: 1492px) {
        font-size: 80px;
        line-height: normal;
    }
}

.gradient {
    background: linear-gradient(92.68deg, #4693E8 -41.18%, #6C53FF 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.subtitle {
    font-size: 31px;
    line-height: 28px;

    @media screen and (max-width: 576px) {
        font-size: 34px;
    }

    @media screen and (max-width: 405px) {
        font-size: 24px;
    }

    @media screen and (min-width: 1024px) {
        font-size: 60px;
        margin-top: 10px;
    }

    @media screen and (min-width: 1492px) {
        font-size: 80px;
        line-height: normal;
        margin-top: 0px;
    }
}

.text {
    margin-bottom: 15px;
    line-height: 24px;
    font-weight: 400;
    font-size: 16px;

    &.light {
        color: colors.$textColorLight;
        opacity: 70%;
    }

    &.dark {
        color: #fff;
        opacity: 0.7;
    }

    @media screen and (max-width: 576px) {
        font-size: 18px;
        line-height: 36px;
        margin-bottom: 0px;
    }
    @media screen and (max-width: 405px) {
        font-size: 16px;
        line-height: 26px;
    }
    @media screen and (min-width: 1024px) {
        font-size: 16px;
        padding: 0;
        max-width: 835px;
    }

    @media screen and (min-width: 1492px) {
        font-size: 22px;
        max-width: 1140px;
        padding: 0;
    }
}

.iconContainer {
    overflow: hidden;
    width: 100%;
    height: 110px;
    position: relative;
    cursor:pointer;

    @media screen and (max-width: 576px) {
        height: 55px;
    }

    &:hover .iconWrapper {
        animation-play-state: paused; 
    }
}

.iconWrapper {
    display: flex;
    position: absolute; 
    animation: scrollIcons var(--animation-duration) linear infinite;
    animation-play-state: running;
    &.spaceBetween{
        justify-content: space-between;
        width: 100%;
    }
}

@keyframes scrollIcons {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(-50%);
    }
}

.modelIcon {
    display: flex;
    padding: 7px 20px;
    height: 110px;
    width: 110px;
    height: 52px;
    width: 52px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    margin-right: 1.2rem;


    @media screen and (min-width: 670px) {
        margin-right: 32px;
    }

    @media screen and (min-width: 796px) {
        margin-right: 3rem;
        height: 52px;
        width: 52px;
    }


    @media screen and (min-width: 912px) {
       margin-right: 4rem;
       height: 52px;
       width: 52px;
    }

    @media screen and (min-width: 1024px) {
        margin-right: 3.2rem;
        height: 72px;
        width: 72px;
    }

    @media screen and (min-width: 1140px) {
        margin-right: 3rem;
        height: 92px;
        width: 92px;
        height: 92px;
        width: 92px;
    }

    @media screen and (min-width: 1320px) {
        margin-right: 4.2rem;
    }

    @media screen and (min-width: 1492px) {
        height: 110px;
        width: 110px;
        margin-right: 3.8rem;
    }

    @media screen and (min-width: 1650px) {
       margin-right: 5rem;
    }

    @media screen and (min-width: 1820px) {
        margin-right: 6rem;
    }

    @media screen and (min-width: 2020px) {
        margin-right: 7rem;
    }
    @media screen and (min-width: 2230px) {
        margin-right: 9.3rem;
    }
}

.icon1 {
    background: rgba(224, 214, 254, 0.60);

    @media screen and (max-width: 1492px) {
        svg {
            height: 70px;
            width: 70px;
        }
    }

    @media screen and (max-width: 1140px) {
        svg {
            height: 60px;
            width: 60px;
        }
    }
    @media screen and (max-width: 912px) {
        svg {
            height: 50px;
            width: 50px;
        }
    }
    @media screen and (max-width: 796px) {
        svg {
            height: 50px;
            width: 50px;
        }
    }
}

.icon2 {
    background: rgba(112, 165, 151, 0.20);

    @media screen and (max-width: 1492px) {
        svg {
            height: 72px;
            width: 74px;
        }
    }

    @media screen and (max-width: 1140px) {
        svg {
            height: 62px;
            width: 64px;
        }
    }

    @media screen and (max-width: 912px) {
        svg {
            height: 52px;
            width: 54px;
        }
    }

    @media screen and (max-width: 796px) {
        svg {
            height: 52px;
            width: 54px;
        }
    }
}

.icon3 {
    background: rgba(242, 233, 130, 0.20);

    @media screen and (max-width: 1492px) {
        svg {
            height: 72px;
            width: 74px;
        }
    }

    @media screen and (max-width: 1140px) {
        svg {
            height: 62px;
            width: 64px;
        }
    }

    @media screen and (max-width: 912px) {
        svg {
            height: 52px;
            width: 54px;
        }
    }
    @media screen and (max-width: 796px) {
        svg {
            height: 52px;
            width: 54px;
        }
    }
}

.icon4 {
    background: rgba(204, 155, 122, 0.20);

    @media screen and (max-width: 1492px) {
        svg {
            height: 74px;
            width: 74px;
        }
    }

    @media screen and (max-width: 1140px) {
        svg {
            height: 62px;
            width: 64px;
        }
    }
    @media screen and (max-width: 912px) {
        svg {
            height: 52px;
            width: 54px;
        }
    }
    @media screen and (max-width: 796px) {
        svg {
            height: 52px;
            width: 54px;
        }
    }
}

.icon5 {
    background: rgba(0, 83, 247, 0.10);

    @media screen and (max-width: 1492px) {
        svg {
            height: 74px;
            width: 74px;
        }
    }

    @media screen and (max-width: 1140px) {
        svg {
            height: 64px;
            width: 64px;
        }
    }
    @media screen and (max-width: 912px) {
        svg {
            height: 54px;
            width: 54px;
        }
    }

    @media screen and (max-width: 796px) {
        svg {
            height: 54px;
            width: 54px;
        }
    }
}

.icon6 {
    background: rgba(249, 171, 0, 0.10);

    @media screen and (max-width: 1492px) {
        svg {
            height: 74px;
            width: 74px;
        }
    }

    @media screen and (max-width: 1140px) {
        svg {
            height: 64px;
            width: 64px;
        }
    }
    @media screen and (max-width: 912px) {
        svg {
            height: 54px;
            width: 54px;
        }
    }
    @media screen and (max-width: 796px) {
        svg {
            height: 54px;
            width: 54px;
        }
    }
}

.icon7 {
    background: rgba(7, 110, 255, 0.10);

    @media screen and (max-width: 1492px) {
        svg {
            height: 60px;
            width: 60px;
        }
    }

    @media screen and (max-width: 1140px) {
        svg {
            height: 50px;
            width: 50px;
        }
    }
    @media screen and (max-width: 912px) {
        svg {
            height: 40px;
            width: 40px;
        }
    }
    @media screen and (max-width: 796px) {
        svg {
            height: 40px;
            width: 40px;
        }
    }
}

.icon8 {
    background: rgba(7, 110, 255, 0.10);

    @media screen and (max-width: 1492px) {
        svg {
            height: 14px;
            width: 66px;
        }
    }

    @media screen and (max-width: 1140px) {
        svg {
            height: 12px;
            width: 56px;
        }
    }
    @media screen and (max-width: 1024px) {
        svg {
            height: 8px;
            width: 46px;
        }
    }
    @media screen and (max-width: 796px) {
        svg {
            height: 8px;
            width: 46px;
        }
    }
}

.icon9 {
    background: rgba(224, 214, 254, 0.30);

    @media screen and (max-width: 1492px) {
        svg {
            height: 66px;
            width: 66px;
        }
    }

    @media screen and (max-width: 1140px) {
        svg {
            height: 56px;
            width: 56px;
        }
    }
    @media screen and (max-width: 912px) {
        svg {
            height: 46px;
            width: 46px;
        }
    }
    @media screen and (max-width: 796px) {
        svg {
            height: 46px;
            width: 46px;
        }
    }
}

.icon10 {
    background: rgba(217, 217, 213, 0.20);

    @media screen and (max-width: 1492px) {
        svg {
            height: 69px;
            width: 68px;
        }
    }

    @media screen and (max-width: 1140px) {
        svg {
            height: 59px;
            width: 58px;
        }
    }
    @media screen and (max-width: 912px) {
        svg {
            height: 49px;
            width: 48px;
        }
    }
    @media screen and (max-width: 796px) {
        svg {
            height: 49px;
            width: 48px;
        }
    }
}