@use "../../../scss/colors";

.container {
  width: 100%;
  height: 100%;
}

.featureContainer {
  padding: 6% 5% 5%;
  position: relative;

  @media screen and (max-width: 576px) {
    padding: 40px 20px;
  }

  @media screen and (min-width: 1024px) {
    padding: 80px 0px;
  }

  @media screen and (min-width: 1492px) {
    padding: 125px 0px;
  }

  @media screen and (min-width: 1620px) {
    padding: 146px 0px;
  }
  &.light {
    background-color: rgba(255, 255, 255, 0.1);
  }

  &.dark {
    background: rgba(42, 40, 49, 0.5);
  }
}

.textContainer {
  display: flex;
  flex-direction: column;
  gap: 15px;
  @media screen and (max-width: 769px) {
    gap: 4px;
  }
  @media screen and (min-width: 1492px) {
    gap: 25px;
  }
}

.title {
  margin: 0px auto 0px auto;
  text-align: center;
  font-size: 24px;
  font-weight: 700;

  &.light {
    background: linear-gradient(92.68deg, #4693E8 -41.18%, #6C53FF 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  &.dark {
    background: var(--btn-color, linear-gradient(93deg, #4693E8 -41.18%, #6C53FF 100%));
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
  }
  @media screen and (max-width: 576px) {
    font-size: 34px;
    line-height: 54px;
  }
  @media screen and (max-width: 405px) {
    font-size: 26px;
    line-height: normal;
  }
  @media screen and (min-width: 769px) {
    font-size: 40px;
    line-height: 46px;
  }

  @media screen and (min-width: 1492px) {
    font-size: 60px;
    line-height: 64px;
  }
}

.desc {
  text-align: center;
  line-height: 26px;
  font-size: 16px;
  font-weight: 400;

  &.light {
    color: colors.$textColorLight;
    opacity: 70%;
  }

  &.dark {
    color: #fff;
    opacity: 0.7;
  }
  @media screen and (max-width: 769px) {
    font-size: 10px;
  }
  @media screen and (max-width: 576px) {
    font-size: 18px;
    margin-top: 20px;
    width: 337px;
    margin-left: auto; 
    margin-right: auto;
  }
  @media screen and (max-width: 405px) {
    font-size: 16px;
    margin-top: 20px;
    width: 300px;
    margin-left: auto; 
    margin-right: auto;
  }
  @media screen and (min-width: 1492px) {
    font-size: 22px;
    line-height: 26px;
  }
}

.row {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 30px;
  @media screen and (max-width: 576px) {
    margin: 20px 0px !important;
    gap: 70px;
  }
  @media screen and (max-width: 405px) {
    gap: 55px;
    margin: 20px 0px !important;
  }
  @media screen and (min-width: 1024px) {
    flex-direction: row;
    justify-content: center;
    padding: 0 20px;
    margin-top: 50px;
    gap: 6px;
  }
  @media screen and (min-width: 1290px) {
    flex-direction: row;
    justify-content: center;
    padding: 0 20px;
    margin-top: 50px;
    gap: 40px;
  }
  @media screen and (min-width: 1492px) {
    justify-content: center;
    margin-top: 70px;
    gap: 70px;
  }

}

.col {
  padding: 0 10px;
  margin-top: 30px;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 576px) {
    margin-top: 0px;
    padding: 20px !important;
    height: 350px;
  }
  @media screen and (min-width: 1024px) {
    width: 286.5px;
    margin-top: 0px;
    padding: 20px;

  }

  @media screen and (min-width: 1492px) {
    width: 315px;
    margin-top: 0px;
    text-align: left;
    align-items: flex-start;
    justify-content: flex-start;

  }

  &:hover {
    background: #5B71F5;
    border-radius: 20px;
    cursor: pointer;
    @media screen and (max-width: 1024px) {
      padding-bottom: 10px;
    }
    .text {
      color: #ffffff;
      opacity: 70%;
    }

    .h4 {
      color: #ffffff;
    }

    svg {
      & path {
        stroke: #ffffff !important;
      }
    }
  }
}

.icon {
  width: 45px;
  height: 45px;

  @media screen and (min-width: 769px) {
    width: 70px;
    height: 70px;
  }
}

.h4 {
  margin: 20px 0 12px 0;
  font-size: 20px;

  &.light {
    color: colors.$textColorLight;
  }

  &.dark {
    color: #fff;
  }
  @media screen and (max-width: 576px) {
    margin: 40px 0 12px 0;
  font-size: 22px;
  }
  @media screen and (max-width: 405px) {
    font-size: 19.5px;
  }
  @media screen and (min-width: 769px) {
    margin: 40px 0 12px 0;
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
  }
  @media screen and (min-width: 14920px) {
    margin: 40px 0 12px 0;
    font-size: 22px;
    line-height: 30px;
  }
}

.text {
  line-height: 24px;
  font-size: 14px;

  &.light {
    color: colors.$textColorLight;
    opacity: 70%;
  }

  &.dark {
    color: colors.$white;
    opacity: 0.7;
  }
  @media screen and (max-width: 576px) {
  font-size: 16px;
  }
  @media screen and (max-width: 405px) {
    font-size: 14px;
  }
  @media screen and (min-width: 769px) {
    line-height: 24px;
    font-size: 14px;
  }
  @media screen and (min-width: 1492px) {
    line-height: 26px;
    font-size: 16px;
  }
}