@use "../../../../../../scss/fonts";
@use "../../../../../../scss/colors";
@use "../../../../../../scss/break-points";

.pendingHeading {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 30px;

  &.light {
    color: colors.$textColorLight;
  }

  &.dark {
    color: colors.$white;
  }
}

.teamTable {
  width: 100%;
  border-radius: 20px;

  @media (max-width: 862px) {
    border-radius: 0;
  }

  thead,
  tbody {
    display: flex;
    flex-direction: column;
    width: 100%;

    .separator {
      width: 100%;
      display: none;

      @media (max-width: 862px) {
        display: block;
      }
    }
  }

  tbody {
    border-radius: 24px;

    @media (max-width: 862px) {
      border-radius: 0px;
    }

  }

  tr {
    display: flex;

    @media (max-width: 862px) {
      border-bottom: none;
    }
  }

  tr:last-child {
    border-bottom: none;
  }

  th,
  td {
    font-style: normal;
    white-space: nowrap;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    text-align: left;
  }

  th {
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    padding: 14px 0 14px 16px;
    margin-top: -2px;

    &:first-child {
      border-radius: 19px 0 0 0;
      margin-left: -2px;
    }

    &:last-child {
      border-radius: 0 19px 0 0;
      margin-right: -2px;
    }

    @media (max-width: 862px) {
      background-color: transparent;
      padding: 5px 10px 5px 10px;
      font-size: 13px;
      margin-right: 1px;

      &:first-child {
        border-radius: 0;
      }

      &:last-child {
        border-radius: 0;
      }
    }
  }

  td {
    padding: 16px 0 16px 16px;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;

    @media (max-width: 862px) {
      padding: 5px 10px 5px 10px;
      font-weight: 400;
      font-size: 12px;
    }
  }

  tr {
    th {
      &:first-child {
        padding-left: 16px;
      }
    }

    td {
      display: flex;
      align-items: center;

      &:first-child {
        padding-left: 16px;

        .name {
          display: block;
        }

        .firstChild {
          width: 100%;
          display: none;
          flex-direction: column;
          align-items: flex-start;
          white-space: nowrap;
          padding: 2px 17px;

          @media (max-width:break-points.$sm) {
            padding: 7px 12px;
          }

          div {
            max-width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            display: inline-block;
          }

          @media (max-width: 862px) {
            display: flex;
          }
        }
      }
    }

    td {
      font-family: fonts.$regularFont;
    }

    td,
    th {
      &:nth-child(1) {
        width: 17%;

        @media (max-width: 862px) {
          width: 125px;
        }
      }

      &:nth-child(2) {
        width: 19%;

        @media (max-width: 862px) {
          width: 199px;
        }
      }

      &:nth-child(3) {
        width: 14%;

        @media (max-width: 862px) {
          width: 110px;
        }
      }

      &:nth-child(4) {
        width: 14%;

        @media (max-width: 862px) {
          width: 98px;
        }
      }

      &:nth-child(5) {
        width: 11%;

        @media (max-width: 862px) {
          width: 123px;
        }
      }

      &:nth-child(6) {
        width: 11%;

        @media (max-width: 862px) {
          width: 123px;
        }
      }

      &:nth-child(7) {
        width: 11%;

        @media (max-width: 862px) {
          width: 99px;
        }
      }

      &:nth-child(8) {
        width: 7%;
        cursor: pointer;
        position: relative;

      }
    }
  }
}

.teamTableLight {
  color: colors.$textColorLight;

  thead {
    @media (max-width:862px) {
      border-bottom: 1px solid #F6F6F6;
    }
  }

  thead,
  tbody {
    .separator {
      border-bottom: 1px solid rgba(colors.$textColorLightMode, 0.03);
    }
  }

  tbody {
    background: colors.$darkModeText;

    @media (max-width:862px) {
      padding-bottom: 9px;
    }

  }

  tr {
    border-bottom: 1px solid rgba(colors.$textColorLightMode, 0.03);

    @media (max-width: 862px) {
      border-bottom: none;
    }

    @media (max-width:break-points.$md) {
      height: 50px;
      padding: 13px 0px;
      border-radius: 10px 10px 0px 0px;
    }
  }
}

.teamTableDark {
  color: colors.$white;

  thead {
    @media (max-width:862px) {
      border-bottom: 1px solid #171718;
      color: #fff;
    }
  }

  thead,
  tbody {
    .separator {
      border-bottom: 1px solid rgba(colors.$darkModeText, 0.03);
    }
  }

  tbody {
    background: colors.$tbodybackground;

    @media (max-width: 862px) {
      background: #252526;
      padding-bottom: 9px;
    }
  }

  tr {
    border-bottom: 1px solid rgba(colors.$darkModeText, 0.03);

    @media (max-width: break-points.$md) {
      border-bottom: 0px;
    }

    @media (max-width:break-points.$md) {
      height: 50px;
      padding: 13px 0px;
      border-radius: 10px 10px 0px 0px;
    }
  }
}

.scrollContainer {
  overflow: auto;
  margin-left: 7px;
  margin-right: 7px;
  -webkit-overflow-scrolling: touch;
  /* Enable scrolling when needed */

  &::-webkit-scrollbar {
    width: 12px;
    /* Width of the vertical scrollbar */
    height: 13px;
    /* Keep the height of the scrollbar itself */
  }

  &::-webkit-scrollbar-track {
    background: #F4F4F5;
    /* Background color of the scrollbar track */
    border-radius: 10px;
    padding: 4px 0;
    /* Increase padding inside the track to increase its height visually */
  }

  &::-webkit-scrollbar-thumb {
    background: #D2D3D5;
    /* Background color of the scrollbar thumb */
    border-radius: 10px;
    border: 4px solid transparent;
    /* Keeps the thumb the same size */
    background-clip: padding-box;
    /* Ensures border doesn't overlap with the thumb */
    height: 6px;
    /* Keep the visual height of the thumb */
  }


  &::-webkit-scrollbar-thumb:hover {
    background: #D2D3D5;
    /* Color when hovering over the scrollbar thumb */
  }
}

.scrollContainerDark {
  overflow: auto;
  margin-left: 7px;
  margin-right: 7px;
  -webkit-overflow-scrolling: touch;
  /* Enable scrolling when needed */

  &::-webkit-scrollbar {
    width: 12px;
    /* Width of the vertical scrollbar */
    height: 13px;
    /* Keep the height of the scrollbar itself */
  }

  &::-webkit-scrollbar-track {
    background: #373434;
    ;
    /* Background color of the scrollbar track */
    border-radius: 10px;
    padding: 4px 0;
    /* Increase padding inside the track to increase its height visually */
  }

  &::-webkit-scrollbar-thumb {
    background: #545454;
    /* Background color of the scrollbar thumb */
    border-radius: 10px;
    border: 4px solid transparent;
    /* Keeps the thumb the same size */
    background-clip: padding-box;
    /* Ensures border doesn't overlap with the thumb */
    height: 6px;
    /* Keep the visual height of the thumb */
  }


  &::-webkit-scrollbar-thumb:hover {
    background: #545454;
    /* Color when hovering over the scrollbar thumb */
  }
}

.headSeparator {
  @media (max-width: break-points.$md) {
    height: 9px;
    left: 0px;
    right: 0px;
    position: absolute;

    &.light {
      background: #F8FBFF !important;
    }

    &.dark {
      background: #181819 !important
    }
  }

}

