@use "../../../../../../../scss/break-points";

.container {
  padding: 30px 48px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: break-points.$sm) {
    padding: 15px 20px;
  }

  .message {
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px;
    margin-bottom: 25px;

    @media (max-width: break-points.$sm) {
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 15px;
    }
  }

  .buttonText {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    padding: 0 24px;

    @media (max-width: break-points.$sm) {
      font-size: 14px;
      padding: 0 15px;
    }
  }
}
