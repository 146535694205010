@use "../../../../scss/break-points";
@use "../../../../scss/colors";

.credits {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.textAreaContainer {
  width: 100%;
  padding: 10px 0 10px 16px;
  display: flex;
  border-radius: 25px;
  box-sizing: border-box;
  cursor: text;
  flex-direction: column;

  &.light {
    border: 1px solid colors.$searchLightMode;
    background: #EDF1FE;

    &.focus {
      background: colors.$searchfieldActive;
    }

    &.activeborder {
      border: none;
      background: #EDF1FE;
      padding: 5px 0 5px 16px;

      @media (max-width: break-points.$sm) {
        padding: 4.5px 0 4.5px 12px;
      }

      @media (max-width: 355px) {
        padding: 4.5px 0 4.5px 3px;
      }
    }

    &.file {
      max-height: 400px;
    }
  }

  &.dark {
    background-color: colors.$searchDarkMode;

    &.focus {
      background-color: colors.$searchfieldActive;
    }

    &.activeborder {
      border: none;
      background: #252526;
      padding: 5px 0 5px 16px;

      @media (max-width: break-points.$sm) {
        padding: 4.5px 0 4.5px 12px;
      }
    }
  }

  &.Privateborder {
    background: var(--btn-color, linear-gradient(93deg, #4693E8 -41.18%, #6C53FF 100%));
  }

  @media (max-width: break-points.$sm) {
    padding: 4.5px 0 4.5px 12px;
  }

  .textarea {
    width: 100%;
    resize: none;
    background-color: transparent;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    max-height: 35vh;
    min-height: 24px !important;
    padding-right: 16px;
    white-space: pre-line;
    margin-left: 5px;
    margin-top: 5px;
    touch-action: manipulation;
    -webkit-touch-callout: none;

    @media (max-width: 890px) {
      font-size: 13px;
      padding-right: 7px;
    }

    @media (max-width: 850px) {
      font-size: 14px;
      padding-right: 7px;
    }

    @media (max-width: 837px) {
      font-size: 13px;
      padding-right: 7px;
    }

    @media (max-width: break-points.$sm) {
      margin-left: 0px;
    }

    &.light {
      color: #2a2831;

      &::placeholder {
        color: #A8B7DD;

      }
    }

    &.Privatetextarea {
      color: white;
    }

    &.dark {
      color: colors.$darkModeText;

      &::placeholder {
        color: #636268;
      }

    }

    &.Privatetextarea {
      color: white;
    }

    &.placeholder {
      @media (max-width: 890px) {
        font-size: 13px;
      }

      @media (max-width: 370px) {
        font-size: 11px;
      }
    }
      @media (max-width: break-points.$sm) {
      margin-top: 3px;
      padding-right: 0px;
      font-size: 13px;
    }

    @media (max-width: 480px) {
      margin-top: 6px;
      line-height: 18px;
    }
    @media (max-width: 355px) {
      margin-top: 3px;
      font-size: 12px;
    }

    &.Privatetextarea {
      &::placeholder {
        color: #fff;
      }
    }

    &.textAreaUploadFile {
      max-height: 25vh;
    }
  }

  .loaderContainer {
    width: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    @media (max-width: break-points.$sm) {
      width: 40px;
    }

    @media (max-width: 355px) {
      margin-right: -9px;
    }
  }

  .chatfooterbtn {
    margin-right: 10px;
    display: flex;
    align-items: end;

    @media (max-width: 355px) {
      margin-right: 4px !important;
    }
  }


}

.fileDisplay {
  display: flex;
  align-items: center;
  padding: 14px;
  border-radius: 10px;
  width: 200px;
  position: relative;
  flex: 0 1 calc(30% - 10px);
  margin: 5px;
  box-sizing: border-box;

  &.light {
    background-color: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    color: #2a2831;
  }

  &.dark {
    background: #252526;
    color: #fff;
    border: 1px solid #2D2D2D
  }

  @media (max-width: break-points.$sm) {
    flex: 0 1 calc(80% - 10px);
    width: 150px;
  }

  @media (max-width: 340px) {
    padding: 11px;
    width: 140px;
  }
}

.fileIcon img {
  width: 36px;
  height: 36px;
}

.fileInfo {
  flex-grow: 1;
  margin-left: 10px;
  @media (max-width: break-points.$sm) {
    margin-left: 5px;
  }
  @media (max-width: 340px) {
    margin-left: 2px;
  }
}

.fileName {
  font-size: 12px;
  font-weight: 500;
  width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (max-width: break-points.$sm) {
    font-size: 11px;
    font-weight: bold;
    width: 48px;
  }
}

.fileType {
  font-size: 11px;
  color: #A09FA2;
  font-weight: 500;
  line-height: 19px;
}

.closeButton {

  cursor: pointer;
  position: absolute;
  right: 10px;
  display: flex;
  justify-content: center;
  border-radius: 50%;
  margin-bottom: 22px;

  &.light{
    color: white;
    background-color: #71717A;
    padding: 2px;
  }

  &.dark{
    color: black;
  }
}

.fileContainer {
  display: flex;
  flex-wrap: wrap;
  max-height: 150px;
  overflow-y: auto;
  padding-top: 10px;

  @media (max-width: break-points.$sm) {
    padding-left: 0px;
  }
}

.loader {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  margin-right: 10px;

  &.light{
    background-color: #fff;
    border: 1px solid #EBEBEB;
  }
  &.dark{
    background-color: transparent;
    border: 1px solid #2D2D2D;;
  }
}

.closeButtonLoader {
  position: absolute;
  right: 10px;
  display: flex;
  justify-content: center;
  margin-bottom: 22px;
  padding: 3px;
}

.docUploadIcon {
  align-items: end;
  display: flex;
  margin-bottom: 4px;
  cursor: pointer;

  @media (max-width: break-points.$sm) {
    margin-bottom: 3px;
  }
}

.customTextarea {
  height: 52px !important;
}