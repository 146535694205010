@use "../../../../../../../scss/colors";

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  border-radius: 40px;
  padding: 9px 15px;
  background-color: transparent;
  margin-top: 10px;
  cursor: text;
  height: 40px;

  svg{
    @media (max-width: 445px) {
      width: 22px;
      height: 24px;
      }
  }
  &.isbackdrop{
    opacity: 0.4;
    pointer-events: none;
  }

  &.light {
    border: 1px solid rgba(colors.$textColorLightMode, 0.60);
  }

  &.dark {
    border: 1px solid colors.$darkModeText;
  }

  &.sidebarfield {
    border: 1px solid colors.$darkModeText;
  }

  &.gptSearchField{
    border: 1px solid rgba(colors.$darkModeText, 0.60);
  }

  .searchField {
    width: 100%;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    background-color: transparent;
    border: none;
    padding: 0;
    margin: 0;

    &::placeholder {
      color: colors.$darkModeText;
    }

    &.light {
      color: colors.$promptplaceholderLight;
      &::placeholder {
        color: colors.$promptplaceholderLight;
      }
    }

    &.dark {
      color: colors.$darkModeText;
    }

    &.searchPrompt {
      &.dark {
        &::placeholder {
      color: #FFF;
      opacity: 0.6;
        }
      }
      &::placeholder {
        
      @media (max-width: 445px) {
        font-size: 13px;
        }
      }
    }

    &.sidebarfield {
      color: colors.$darkModeText;
      &::placeholder {
        color: colors.$darkModeText;
      }
    }

    &.gptModal{
      &::placeholder {
        color: rgba(colors.$darkModeText, 0.60);
      }
    }
  }

  &.withFocus:focus-within {
    border: 1px solid colors.$primaryColor;
  }
  &.addPrompt:focus-within {
    border: 1px solid #527AE6;
  }

  .removeBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;
    border: none;
    background-color: transparent;

    svg {
      width: 20px;
      height: 20px;
      & path {
        fill: colors.$darkModeText;
      }
    }

    &.light{
      svg {
        width: 20px;
        height: 20px;
        & path {
          fill: #7A7880;
        }
      }
    }
  }
}