@use "../../../../../../../scss/colors";
@use "../../../../../../../scss/break-points";

.itemContainer {
  display: grid;
  grid-template-columns: min-content minmax(0, auto);
  align-items: center;
  cursor: pointer;
  margin-bottom: 1px;
  gap: 6px;
  background-color: transparent;
  padding: 6px 17px 6px 1px;
  // margin-left: 2px;

  &:hover {
    .deleteContainer {
      opacity: 1;
      visibility: visible;
    }

    .itemText {
      max-width: 84%;
    }
  }
}

.deleteContainer {
  opacity: 0;
  visibility: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 15px;

  .actionIcon {
    margin-left: 10px;
    cursor: pointer;
  }

  &.itemField {
    right: 18px;
  }
}

.itemContainerDark {

  &:hover {
    opacity: 1;
    background-color: colors.$chatItembackground;
  }

  &.active {
    opacity: 1;
    background-color: colors.$chatItembackground;
  }
}

.itemContainerLight {

  &:hover {
    opacity: 1;
    background-color: #92A5ED;
  }

  &.active {
    opacity: 1;
    background-color: #92A5ED;
  }
}

.itemText {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 28px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  max-width: 84%;
  width: 84%;

  &:hover {
    max-width: 84%;
    width: 84%;

    @media (max-width: break-points.$md) {
      width: 74%;
    }
  }

  &.isActive {
    width: 84%;

    @media (max-width: break-points.$md) {
      width: 74%;
    }
  }

  &.itemFieldAdjust {
    max-width: 78%;
    width: 78%;
  }

  &.itemField {
    max-width: 60%;
    width: 60%;
  }

  @media (max-width: break-points.$md) {
    width: 74%;
  }

  .highlightText {
    color: colors.$darkModeText;
    background-color: colors.$primaryColor;
  }
}

.itemLastMessage {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;


  .highlightText {
    color: colors.$darkModeText;
    background-color: colors.$primaryColor;
  }
}

.itemMsgActionContainer {
  width: 100%;
}

.itemMsgActionContainerDark {
  color: colors.$darkModeText;

  // &.active {
  //   svg {
  //     & path {
  //       fill: colors.$darkModeText;
  //     }
  //   }
  // }
}

.itemMsgActionContainerLight {
  color: colors.$lightThemeColor;

  // &.active {
  //   svg {
  //     & path {
  //       fill: colors.$darkModeText;
  //     }
  //   }
  // }
}

.itemMessageContainer {
  max-width: 100%;
}

.editItemTextfield {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 28px;
  padding-bottom: 0px;
  border-bottom: 2px solid colors.$primaryColor;
  background-color: transparent;
  color: colors.$darkModeText;
  width: 84%;

  &.editField {
    width: 60%;
  }

  &.chatFolderField {
    margin-right: 32px;
    font-size: 16px;
  }

  @media (max-width: break-points.$md) {
    width: 74%;
  }
}

.actionsContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 18px;

  svg {
    & path {
      fill: #c9cbf5;
    }
  }

}

.actContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  // right: 10px;
  gap: 5px;
}

.actContainer.editOnly {
  right: 34px
}

.actContainer.IconAdjustLg {
  right: 37px;
}

.actContainer.IconAdjust {
  right: 40px
}

.deleteIcon {
  display: none;
}

.actionIcon {
  cursor: pointer;
}

.highlightText {
  color: colors.$darkModeText;
  background-color: colors.$primaryColor;
}

.check {
  display: flex;
  align-items: center;
  gap: 7px;
}

.check input[type="checkbox"] {
  // margin-right: 4px; 
  width: 10px;
  height: 10px;
  border-radius: 2px;
  margin-bottom: 3.3px;
  -webkit-appearance: none;
  background-color: transparent;
  border: 1px solid rgba(255, 255, 255, 0.50);
  cursor: pointer;
  position: relative;
}

.check input[type="checkbox"]:checked {
  // background-color: #fff; 
  border: 1px solid #fff;
}

.check input[type="checkbox"]:checked::after {
  // content: '✓'; 
  // position: absolute;
  // top: 50%;
  // left: 50%;
  // transform: translate(-50%, -50%);
  // // color: #7C4DFF; 
  // color: #fff;
  // font-size: 8px; 
  // font-weight: bold;

  content: '';
  position: absolute;
  top: 50%;
  left: 55%;
  transform: translate(-50%, -50%);
  width: 12px;
  height: 12px;
  background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16"%3E%3Cpath d="M9 16.2l-3.5-3.5 1.4-1.4 2.1 2.1 5.6-5.6 1.4 1.4z" fill="%23fff"%3E%3C/path%3E%3C/svg%3E');
  background-size: contain;
  background-repeat: no-repeat;
}

.deleteIcon {
  display: none;
}

.itemContainer:hover .deleteIcon {
  display: block;
}

.itemContainer:hover .actContainer.editOnly {
  right: 16px;
}

.itemContainer:hover .actContainer.IconAdjustLg {
  right: 17px;
}

.itemContainer:hover .actContainer.IconAdjust {
  right: 19px;
}

.itemContainer:hover input[type="checkbox"] {
  visibility: visible;
  opacity: 1;
}

.hiddenCheckbox {
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.visibleCheckbox {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.3s ease;
}

.additionalactionIcon {
  margin-top: 3px !important;
}