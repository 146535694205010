@use "../../../../scss/break-points";
@use "../../../../scss/colors";

.DropDownbody {
  width: 100%;
  display: flex;
  flex-direction: column;
  max-height: 180px;
  overflow-y: auto;

  .message {
      width: 100%;
      text-align: center;
      color: colors.$white;
      font-size: 16px;
      font-weight: 700;
      line-height: 20px;

      @media (max-width: break-points.$sm) {
          font-size: 14px;
      }
  }
}

  .textContainer {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      @media (max-width: break-points.$sm) {
          margin-left: 15px;
      }

      .modalName {
          font-size: 14px;
          font-weight: 700;
          line-height: 20px;

          @media (max-width: break-points.$sm) {
              font-size: 13px;
          }
      }

      .dropdownModalName {
          font-size: 16px;
          font-weight: 500;
          line-height: 20px;
          color: #A09FA2;

          @media (max-width: break-points.$sm) {
            font-size: 16px;
          }
          @media (max-width: 360px) {
            font-size: 14px;
          }
      }
  }


.svg {
  border-top: 1px solid  #D9D9D94D;
}

// UploadURL
.urlHeading{
  text-align: center;
  font-size: 22px;
  line-height: 20px;
  font-weight: 600;
  padding-bottom: 1.75rem;
}
.form {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;

  .input {
    width: 100%;
    border-radius: 10px 0px 0px 10px;
    border: 1px solid #527AE6;
    background-color: rgba(colors.$darkModeText, 0.03);
    padding: 9px 13px;
    box-sizing: border-box;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;

    &.light {
      color: #2a2831;
      &::placeholder{
      color: #A09FA2;
      }
    }

    &.dark {
      color: colors.$white;
     
      &::placeholder{
        color: colors.$white;
        opacity: 40%;
        }
    }

    @media (max-width: break-points.$sm) {
      font-size: 12px;
    }
    @media (max-width: break-points.$sm) {
      font-size: 10px;
    }
    @media (max-width: 420px) {
      font-size: 9px;
    }
  }

  button {
    padding: 10px 0;
    min-width: 122px;
    box-sizing: border-box;
    border-radius: 0px 10px 10px 0px;
    background: #527AE6;
    color: colors.$white;
    font-size: 17px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;

    @media (max-width: break-points.$sm) {
      min-width: 75px;
      font-size: 12px;
    }
    @media (max-width: break-points.$sm) {
      font-size: 10px;
    }
    &.isLoading {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      padding: 14.5px 0;
    }
  }
}

.disabled{
  opacity: 0.5; /* Example styling for disabled state */
  pointer-events: none; 
}
.uploadIcon{
  cursor: not-allowed;
  opacity: 0.5;
svg{
  @media (max-width: break-points.$sm) {
height: 25px;
width: 26px;
}
@media (max-width:355px) {
  height: 22px;
  width: 23px;
  }
}
  &.privateuploadIcon{
    opacity: 1;
  }
}

.uploadingIcon{
  svg{
    @media (max-width: break-points.$sm) {
  height: 25px;
  width: 26px;
  }
  @media (max-width:355px) {
    height: 22px;
    width: 23px;
    }
}
}
.supportedFilesContainer{
position: relative;
left: 15px;
line-height: 22px;
font-size: 12px;
&.light{
  color: #A09FA2;
}
&.dark{
  color: colors.$white;
  opacity: 40%;
}
@media (max-width: break-points.$sm) {
  font-size: 9px;
 padding-right: 15px;
  line-height: 12px;
  font-weight: 500;
  top: 4px;
  }
}
.SupportedHeader{
  font-weight: 400; 
}
.supportedFiles{
font-weight: 500;
font-style: italic;
margin-left: 2px;

}
