@use "../../../../../../../scss/colors";
@use "../../../../../../../scss/break-points";

.theme {
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-radius: 100px;
  padding: 4px;
  gap: 4px;
  margin-bottom: 10px;

  &.minsidebar{
    width: 48px;
  }

  .themeBox {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid transparent;
    flex: 1 0 0;
    padding: 7px 10px;
    opacity: 0.6;
    cursor: pointer;
    font-weight: 500;

    &.minsidebar{
      width: 38px;
    }

    &.minsidebarDark{
      width: 38px;
      opacity: 1;
      background-color: colors.$darkModeText;
    }

    @media (max-width:break-points.$sm) {
      padding: 0px 10px;
    }
    &.settingsSidebar{
      @media (max-width:break-points.$sm) {
      padding: 7px 10px;
      font-size: 15px;
      }
    }
  }

  .dark {
    opacity: 1;
    border-radius: 100px;
  }

  .light {
    font-weight: 500;
    opacity: 1;
    border-radius: 100px;
  }

  .themeText {
    display: inline-block;
    margin-left: 8px;
  }
}

.themeLight {
  color: colors.$textColorLightMode;
  background: colors.$themeSetting;

  @media (max-width:break-points.$sm) {
    background: #E5EAF1;
    }

  .dark {
    background-color: colors.$darkModeText;
  }

  .light {
    background-color: colors.$white;
    box-shadow: 0px 1px 2px 0px rgba(91, 104, 113, 0.32),
      0px 0px 1px 0px rgba(26, 32, 36, 0.32);
  }
}

.themeDark {
  color: colors.$textColorLightMode;
  background: colors.$themeSetting;

  @media (max-width:break-points.$sm) {
    background: #66688E;
    }

  .dark {
    color: colors.$textColorLightMode;
    background-color: colors.$darkModeText;
  }

  .light {
    color: colors.$textColorLightMode;
    background-color: colors.$white;
    box-shadow: 0px 1px 2px 0px rgba(91, 104, 113, 0.32),
      0px 0px 1px 0px rgba(26, 32, 36, 0.32);
  }
}