@use "../../../../../../../scss/break-points";

.PrivateMain {
    display: flex;
    width: 220px;
    height: 40px;
    padding: 9px 13px;
    align-items: center;
    gap: 7px;
    z-index: 20;

    &.sideBarDD{
        gap: 16px;
    }
}

.headingName {
    color: #FFF;
    font-family: Raleway;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    width: 107px;

    &.sidebarDropDown {
        font-size: 10px;
    }
}

.icon {
    display: flex;
    width: 29.11px;
    height: 27.23px;
    padding: 4px 5px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    flex-shrink: 0;
}

.toggle {
    display: flex;
    width: 37px;
    height: 20px;
    padding: 2px;
    align-items: center;
    gap: 7px;
    flex-shrink: 0;
    border-radius: 300px;
    position: absolute;
    right: 20px;
    // background: #B2A9F0;
    cursor: pointer;

    &.light {
        background: #8B8AF6;
        border: 1px rgba(255, 255, 255, 0.05);
    }

    &.dark {
        border: 1px rgba(255, 255, 255, 0.05);
        background: #333D7D;
    }

    &.adjustToggle {
        // right: 0px;
    }

    &.sidebarDropDown {
        background: #6866B5;
    }

    &.sidebarDropDowndark {
        background: rgba(33, 33, 33, 0.50);
    }

    @media (max-width: break-points.$sm) {
        right: 32px;
    }
}

.toggleCircle {
    width: 15px;
    height: 15px;
    border-radius: 300px;
    background: #FFF;
    box-shadow: 0px 10px 3px 0px rgba(86, 82, 107, 0.00),
        0px 7px 3px 0px rgba(86, 82, 107, 0.01),
        0px 4px 2px 0px rgba(86, 82, 107, 0.05),
        0px 2px 2px 0px rgba(86, 82, 107, 0.09),
        0px 0px 1px 0px rgba(86, 82, 107, 0.10);
    transition: transform 0.3s ease;
}

.toggleCircle.enabled {
    transform: translateX(17px);
    /* Move toggle circle to the right when enabled */
}