@use "../../../../scss/colors";
@use "../../../../scss/break-points";

.button {
  padding: 0;
  margin: 0;
  border-radius: 8px;
  background-color: transparent;
  opacity: 0.6;

  svg{
    @media (max-width: break-points.$sm ) {
      height: 32px;
      width: 32px;
    }
      @media (max-width: 355px) {
        height: 28px;
        width: 28px;
      }
    }
  &.light {
    svg {
      & path {
        stroke: #527AE6;
      }
    }
  }

  &.dark {
    svg {
      & path {
        stroke: #527AE6;
      }
    }
  }

  &.focused {
    opacity: 1;
    svg {
      & path {
        stroke: #527AE6;
      }
    }
  }

  &.darkModeFocused {
    opacity: 1;
    svg {
      & path {
        stroke: #527AE6;
      }
    }
  }

  &.disabled {
    opacity: 0.6;
    cursor: auto;
  }
  &.privatebtn{
    svg {
      & path {
        stroke: colors.$white;
      }
    }
  }
}
