@use "../../scss/colors";
@use "../../scss/variables";
@use "../../scss/break-points";

.menu {
  display: block;
  margin-top: 20px;
}

.logoContainer {
  display: flex;
  align-items: center;
  min-width: 110px;
  gap: 5px;
  margin-bottom: 48px;
}

.list {
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 4;

  @media screen and (min-width: 1120px) {
    padding: 0 20px;
  }

  @media screen and (max-width: 1120px) {
    display: grid;
    grid-template-columns: auto auto auto;
    justify-content: space-between;
  }

  @media screen and (min-width: 1190px) {
    padding: 0 20px;
    gap: 20px;
  }
}

.link {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 28px;
  padding: 4px;
  border-radius: 10px;
  border: 1px solid transparent;
  text-decoration: none;
  display: flex;

  @media (max-width: 346px) {
    font-size: 14px;
  }

  &.light {
    color: colors.$textColorLight;
    opacity: 0.8;
  }

  &.dark {
    color: colors.$white;
  }

  @media screen and (min-width: 900px) {
    margin-top: 0px;
    padding: 10px 0px;
  }
}

.smallscreen {
  @media (max-width: 1120px) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}

.social__container {
  display: flex;
  flex-wrap: wrap;
  max-width: 150px;
  align-items: center;
  max-width: 132px;
  gap: 12px;
  margin-top: 20px;

  @media (max-width: 380px) {
    max-width: 150px;
    gap: 10px;
    margin-top: 15px;
  }

  .socialBtn {
    background-color: transparent;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    text-align: center;
    width: 24px;
    height: 24px;

    .icon {

      &.light {
        color: rgba(91, 113, 245, 1);
        opacity: 0.2;

        &:hover {
          color: colors.$primaryColor;
        }
      }

      &.dark {
        color: rgba(91, 113, 245, 0.70);

        &:hover {
          color: colors.$primaryColor;
        }
      }
    }

  }
}

.select {
  height: 44px;
  max-width: fit-content;

}

.logoHead {
  font-family: Raleway;
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  background: var(--btn-color, linear-gradient(93deg, #4693E8 -41.18%, #6C53FF 100%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  @media screen and (max-width: 1440px) {
    font-size: 30px;
  }
}