@use "../../scss/colors";

.title {
  padding: 48px 0;
  text-align: center;
  @media screen and (max-width: 576px) {
    padding: 20px 0;
    font-size: 34px;
font-style: normal;
font-weight: 700;
line-height: 54px; 
  }
  @media screen and (max-width: 405px) {
    font-size: 26px;
line-height: 34px; 
  }
  &.light {
    color: colors.$textColorLight;
  }

  &.dark {
    color:#fff;
  }
}

.contentDark {
  line-height: 24px;
  white-space: pre-wrap;
  font-size: 16px;
  padding: 0 20px 100px 20px;
  overflow-x: auto;
  color: rgba(255, 255, 255, 0.7);
  @media screen and (max-width: 576px) {
    font-size: 12px;
line-height: 26px; 
padding: 0 20px 20px 20px;
  }
  h4 {
    font-size: 18px;
  }

  table {
    border-collapse: collapse;
    min-width: 500px;
  }

  td,
  th {
    border: 1px solid #E7E7E7;
    padding: 10px;
  }
  th{
    @media screen and (max-width: 576px) {
      font-size: 16px !important;
      color: #fff;
      opacity: 1 !important;
    }
    }
  &.light {
    color: colors.$textColorLight;
  
  }

  &.dark {
    color: colors.$white;
  }
  a{
    text-decoration: underline;
  }
}

.contentLight {
  line-height: 24px;
  white-space: pre-wrap;
  font-size: 16px;
  padding: 0 20px 100px 20px;
  overflow-x: auto;
  color: rgba(42, 40, 49, 0.7);

  @media screen and (max-width: 576px) {
    font-size: 12px;
line-height: 26px; 
padding: 0 20px 20px 20px;
  }

  h4 {
    font-size: 18px;
  }

  table {
    border-collapse: collapse;
    min-width: 500px;
  }

  td,
  th {
    border: 1px solid colors.$borderLight;
    padding: 10px;
  }
  th{
  @media screen and (max-width: 576px) {
    font-size: 16px !important;
    color: #2a2831;
    opacity: 1 !important;
  }
  }
  a{
    text-decoration: underline;
  }
}
.scrollContainer {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  padding: 15px 0px;
  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar {
    height: 9px;
  }

  &::-webkit-scrollbar-track {
    background: #F4F4F5;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: #484848;
    border-radius: 10px;
    border: 2px solid transparent;
    background-clip: padding-box;
    height: 9px; 
  }


  &::-webkit-scrollbar-thumb:hover {
    background: #484848;
  }
}

.scrollContainerDark {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  padding: 15px 0px;
  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar {
    height: 9px;
  }

  &::-webkit-scrollbar-track {
    background: #282828;
    border-radius: 10px;
      }

  &::-webkit-scrollbar-thumb {
    background: #ABABAB;
    border-radius: 10px;
    border: 2px solid transparent;
    background-clip: padding-box;
    /* Ensures border doesn't overlap with the thumb */
    height: 9px;
  }


  &::-webkit-scrollbar-thumb:hover {
    background: #ABABAB;
  }
}
