.container{
    border-radius: 6px;
    padding: 12px;
    gap: 18px;
    display: flex;
    flex-direction: column;
    &.light {
        background-color: #fff;
        border: 0.5px solid #4338CA;
    }

    &.dark {
        background-color: #252526;
        border: 0.5px solid #527AE6;
    }
}
.heading{
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    &.light {
        color: #737373;
    }

    &.dark {
        color: #fff;
        opacity: 0.7;
    }
    @media (max-width:576px) {
        font-size: 11px;
     } 
}
.docContainer{
    display: flex;
    flex-direction: column;
    gap: 5px;
}
.docHeadContainer{
    display: flex;
    gap: 8px;
    align-items: center;
}
.title{
    font-size: 12px;
    font-weight: 600;
    line-height: 15px;
  
    &.light {
        color: #171717;
    }

    &.dark {
        color: #fff;
    }
    @media (max-width:576px) {
        font-size: 11px;
     } 
}
.fileWrapper{
    padding: 19px 10px;
    border-radius: 4px;
    border: 0.5px dashed #94A3B8;
    display: flex;
    flex-direction: column;
    gap: 14px;
    align-items: center;
    justify-content: center;
    text-align: center;
}
.chooseFileWrapper{
    display: flex;
    flex-direction: column;
    gap: 8px;
}
.urlWrapper{
    display: flex;
    gap: 5px;

}