@use "../../../scss/colors";

.faqsConatainer {
  display: flex;
  padding: 100px 0px;
  flex-direction: column;
  align-items: center;
  gap: 119px;
  align-self: stretch;
  @media screen and (max-width: 769px) {
    padding: 80px 0px;
    gap: 100px;
  }
  @media screen and (max-width: 576px) {
    padding: 60px 0px;
    gap: 80px;
  }
}

.headContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 100px;
  @media screen and (max-width: 769px) {
    gap: 80px;
  }
  @media screen and (max-width: 769px) {
    gap: 60px;
  }
}

.textContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 18px;
  align-self: stretch;
  @media screen and (max-width: 769px) {
    gap: 10px;
  }
}

.title {
  text-align: center;
  font-size: 34px;
  line-height: 54px;
  font-weight: 700;
  line-height: normal;

  &.light {
    background: linear-gradient(92.68deg, #4693E8 -41.18%, #6C53FF 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  &.dark {
    background: var(--btn-color, linear-gradient(93deg, #4693E8 -41.18%, #6C53FF 100%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  @media screen and (max-width: 576px) {
    font-size: 34px;
    line-height: 46px;
    max-width: 312px;
  }

  @media screen and (max-width: 405px) {
    font-size: 26px;
    line-height: normal;
  }

  @media screen and (min-width: 769px) {
    font-size: 40px;
  }

  @media screen and (min-width: 1024px) {
    font-size: 60px;
    line-height: normal;
  }

  @media screen and (min-width: 1492px) {
    font-size: 80px;
    line-height: normal;
  }

}

.desc {
  text-align: center;
  line-height: 26px;
  font-size: 18px;      
  padding: 0 20px;

  &.light {
    color: colors.$textColorLight;
  }

  &.dark {
    color: #fff;
  }

  @media screen and (max-width: 576px) {
    font-size: 18px;
    padding: 0 20px;
  }

  @media screen and (max-width: 405px) {
    font-size: 16px;
    padding: 0 20px;
  }

  @media screen and (min-width: 769px) {
    font-size: 20px;
    padding: 0;
  }

  @media screen and (min-width: 1024px) {
    font-size: 26px;
    line-height: normal;
  }

  @media screen and (min-width: 1492px) {
    font-size: 32px;
    line-height: normal;
  }
}

.faqsData {
  display: flex;
  flex-direction: column;
  gap: 15px;

  @media screen and (min-width: 1024px) {
    gap: 20px;
  }
}

.faqsItemContainer {
  display: flex;
  padding: 20px;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 10px;
  cursor: pointer;

  &.light {
    background: #F6F6F6;
  }

  &.dark {
    background: #383940;
  }

  &.expanded {
    background: #F1F2FF;
  }

  &.darkexpanded {
    background: #3A3D59;
  }
}

.faqsDataContainer {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  padding: 0px 40px;

  @media screen and (min-width: 1024px) {
    max-width: 960px;
    padding: 0px;
  }

  @media screen and (min-width: 1492px) {
    max-width: 1044px;
  }
}

.faqsQuestion {
  font-family: Raleway;
  font-size: 18px;
  line-height: 26px;
  font-style: normal;
  font-weight: 700;
  &.light{
    color: #2A2831;
  }
  &.dark{
    color: #FFF;
  }
  @media screen and (min-width: 769px) {
    font-size: 20px;
    line-height: 26px;
    /* 125% */
  }

  @media screen and (min-width: 1024px) {
    font-size: 22px;
    line-height: 28px;
    /* 125% */
  }

  @media screen and (min-width: 1492px) {
    font-size: 24px;
    line-height: 30px;
    /* 125% */
  }
}

.faqsAnswers {
  font-family: Raleway;
  font-size: 15px;
  line-height: 20px;
  font-style: normal;
  font-weight: 500;

&.light{
  color: #2A2831;
  opacity: 0.8;
}
&.dark{
  color: #FFF;
  opacity: 0.8;
}
  @media screen and (min-width: 769px) {
    font-size: 16px;
    line-height: 22px;
    /* 125% */
  }

  @media screen and (min-width: 1024px) {
    font-size: 17px;
    line-height: 24px;
    /* 125% */
  }

  @media screen and (min-width: 1492px) {
    font-size: 18px;
    line-height: 26px;
    /* 144.444% */
  }

}

.arrow {
  flex-shrink: 0;
  transform: rotate(0deg);
  transition: transform 0.3s ease;
  cursor: pointer;
&.dark{
  svg {
    & path {
      stroke: #fff;
    }
  }
}
svg{
  width: 25px;
  height: 25px;
  @media screen and (min-width: 769px) {
    width: 30px;
    height: 30px;
  }

  @media screen and (min-width: 1024px) {
    width: 35px;
    height: 35px;
  }

  @media screen and (min-width: 1492px) {
    width: 40px;
    height: 40px;
  }
}
 
}

.arrowUpward {
  transform: rotate(180deg);
}