@use "../../scss/colors";
@use "../../scss/break-points";

.container {
  &.light {
    background-color: colors.$white;
  }

  &.dark {
    background-color: #181819;
  }

  &.isShare{
    background-color: colors.$shareChatLight;
  }
}

.content {
  width: 100%;
  height: 100%;
}

.documentModalContainer {
  padding: 40px 30px;
  width: 100%;
  height: 100%;

  @media (max-width: break-points.$sm) {
    padding: 15px;
  }

  &.dragDocument{
    padding: 0px;
  }
}