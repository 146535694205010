@use "../../../../../../scss/break-points";
@use "../../../../../../scss/colors";
.container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;

  &:hover{

    &.light{
    background: rgba(84, 125, 238, 0.15);
    }

    &.dark{
      background: #2A3249;
    }
   }

  .imageContainer {
    width: 40px;
    height: 40px;
    background-color: transparent;
    border-radius: 25px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    @media (max-width: break-points.$sm) {
      width: 35px;
      border-radius: 25px;
      flex-shrink: 0;
    }
  }
  .image{
  background-color: white;
  border-radius: 50%;
  padding: 2px;
  }

  .textContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 25px;

    .modalName {
      font-size: 14px;
      font-weight: 700;
      line-height: 20px;

      @media (max-width: break-points.$sm) {
        font-size: 13px;
      }
    }

    .modelCreditPerQuery {
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      opacity: 0.6;

      @media (max-width: break-points.$sm) {
        font-size: 13px;
      }
    }
  }
}
