@use "../../../../../scss/colors";

.header {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}

.headerTextContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.crossBtn {
  background-color: transparent;
  cursor: pointer;
}

.title {
  font-weight: 700;
  font-size: 22px;
  line-height: 30px;
  display: flex;
  align-items: center;
}

.description {
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
  opacity: 0.7;
  display: inline-block;
}

.buttonText {
  &.light {
    color: colors.$textColorLight;
  }

  &.dark {
    color: colors.$white;
  }
}
