@use "../../../../scss/break-points";
@use "../../../../scss/colors";

.ConversationContainer {
  flex-direction: column !important;
  width: 100%;
}

.textAreaContainer {
  width: 60%;
  padding: 10px 16px 10px 16px;
  display: flex;
  border-radius: 8px;
  box-sizing: border-box;
  cursor: text;
  flex-direction: column;
  min-height: 121px;

  @media (max-width: break-points.$lg) {
    width: 100%;
  }

  &.textContainer {
    width: 100%;
  }

  &.light {
    border: 0.8px solid #4338CA;
    background-color: #FFFFFF;

    &.activeborder {
      padding: 5px 16px 5px 16px;

      @media (max-width: break-points.$sm) {
        padding: 4.5px 12px 4.5px 12px;
      }

      @media (max-width: 355px) {
        padding: 4.5px 3px 4.5px 3px;
      }
    }

    &.file {
      max-height: 400px;
    }
  }

  &.dark {
    border: 0.8px solid #527AE6;
    background-color: #252526;

    &.activeborder {
      padding: 5px 16px 5px 16px;

      @media (max-width: break-points.$sm) {
        padding: 4.5px 12px 4.5px 12px;
      }

      @media (max-width: 355px) {
        padding: 4.5px 3px 4.5px 3px;
      }
    }
  }

  @media (max-width: break-points.$sm) {
    padding: 4.5px 0 4.5px 12px;
  }

  .textarea {
    width: 100%;
    resize: none;
    background-color: transparent;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    max-height: 35vh;
    min-height: 24px !important;
    padding-right: 16px;
    white-space: pre-line;
    margin-left: 5px;
    margin-top: 5px;
    touch-action: manipulation;
    -webkit-touch-callout: none;

    @media (max-width: 890px) {
      font-size: 13px;
      padding-right: 7px;
    }

    @media (max-width: 850px) {
      font-size: 14px;
      padding-right: 7px;
    }

    @media (max-width: 837px) {
      font-size: 13px;
      padding-right: 7px;
    }

    @media (max-width: break-points.$sm) {
      margin-left: 0px;
    }

    &.light {
      color: #171717;
    }

    &.dark {
      color: #fff;

      &::placeholder {
        color: #636268;
      }

    }

    &.placeholder {
      @media (max-width: 890px) {
        font-size: 13px;
      }

      @media (max-width: 370px) {
        font-size: 11px;
      }
    }

    @media (max-width: break-points.$sm) {
      margin-top: 3px;
      padding-right: 0px;
      font-size: 13px;
    }

    // @media (max-width: 480px) {
    //   margin-top: 6px;
    //   line-height: 18px;
    // }
    @media (max-width: 355px) {
      margin-top: 3px;
      font-size: 12px;
    }

    &.textAreaUploadFile {
      max-height: 25vh;
    }
  }

  .loaderContainer {
    width: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    @media (max-width: break-points.$sm) {
      width: 40px;
    }

    @media (max-width: 355px) {
      margin-right: -9px;
    }
  }

  .chatfooterbtn {
    margin-right: 10px;
    display: flex;
    align-items: end;

    @media (max-width: 355px) {
      margin-right: 4px !important;
    }
  }


}

.textarea {
  width: 100%;
  resize: none;
  background-color: transparent;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  max-height: 35vh;
  min-height: 24px !important;
  padding-right: 16px;
  white-space: pre-line;
  margin-left: 5px;
  margin-top: 5px;
  touch-action: manipulation;
  -webkit-touch-callout: none;

  @media (max-width: 890px) {
    font-size: 13px;
    padding-right: 7px;
  }

  @media (max-width: 850px) {
    font-size: 14px;
    padding-right: 7px;
  }

  @media (max-width: 837px) {
    font-size: 13px;
    padding-right: 7px;
  }

  @media (max-width: break-points.$sm) {
    margin-left: 0px;
  }

  &.light {
    color: #2a2831;

    &::placeholder {
      color: #A8B7DD;

    }
  }

  &.Privatetextarea {
    color: white;
  }

  &.dark {
    color: colors.$darkModeText;

    &::placeholder {
      color: #636268;
    }

  }

  &.Privatetextarea {
    color: white;
  }

  &.placeholder {
    @media (max-width: 890px) {
      font-size: 13px;
    }

    @media (max-width: 370px) {
      font-size: 11px;
    }
  }

  @media (max-width: break-points.$sm) {
    margin-top: 3px;
    padding-right: 0px;
    font-size: 13px;
  }

  // @media (max-width: 480px) {
  //     margin-top: 6px;
  //     line-height: 18px;
  // }

  @media (max-width: 355px) {
    margin-top: 3px;
    font-size: 12px;
  }

  &.Privatetextarea {
    &::placeholder {
      color: #fff;
    }
  }

  &.textAreaUploadFile {
    max-height: 25vh;
  }
}

.docUploadIcon {
  align-items: end;
  display: flex;
  margin-bottom: 4px;
  cursor: pointer;

  @media (max-width: break-points.$sm) {
    margin-bottom: 3px;
  }
}

.rightSide {
  border: 0.6px solid #94A3B8;
  width: 100%;
  border-radius: 8px;

  &.light {
    background: #FFFFFF;
    border: 0.6px solid #94A3B8;
  }

  &.dark {
    background: #252526;
    border: 0.6px solid rgba(255, 255, 255, 0.1);
  }

  @media (min-width: 1024px) {
    position: absolute;
    right: 2rem;
    width: 36%;
  }

  &.rightSidePosition {
    position: relative;
    width: 100%;
    right: 0;
  }


}

.Containerbox {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 16px;
  padding: 9px;
  cursor: pointer;
  font-size: 14px;

  &.light {
    background: #EEF2FF;
  }

  &.dark {
    background: #485474;
  }

  &.showcontent {
    width: 145px;

    @media (max-width: break-points.$sm) {
      width: 130px;
    }

    @media (max-width: 444px) {
      width: 82px;
    }

    @media (max-width: 350px) {
      width: 70px;
    }
  }

  &.showcontentClaude {
    width: 120px;
    padding: 9px;
    padding-left: 3px;

    @media (max-width: break-points.$sm) {
      width: 110px;
    }

    @media (max-width: 444px) {
      width: 82px;
    }

    @media (max-width: 350px) {
      width: 70px;
    }
  }

  &.showcontentAI {
    width: 96px;

    @media (max-width: break-points.$sm) {
      width: 85px;
    }

    @media (max-width: 444px) {
      width: 76px;
    }

    @media (max-width: 350px) {
      width: 68px;
    }
  }

  @media (max-width: break-points.$sm) {
    font-size: 12px;
  }
}

.rightContainerbox {
  width: 32px;
  height: 32px;
  border-radius: 8px;
  padding: 9px;

  &.light {
    background: #EEF2FF;
  }

  &.dark {
    background: #485474;
  }
}

.label {
  margin-left: 8px;
  width: 145px;

  &.light {
    color: #171717;
  }

  &.dark {
    color: #fff;
  }

  // opacity: 0;
  // transition: opacity 0.3s ease;

  @media (max-width: 444px) {
    margin-left: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    max-width: calc(100% - 20px);
  }
}

.containerBox:hover {
  width: 150px;
  /* Expanded width on hover */
}

.containerBox:hover .label {
  opacity: 1;
}

.sendContainer {
  background: #4338CA;
}

.SubContainerBox {
  border-radius: 16px;
  padding: 14px;

  &.light {
    background: #D9D9D9;
  }

  &.dark {
    background: #fff;
    opacity: 0.6;
  }
}

.KnowledgeContainer {
  display: flex;
  flex-direction: column;
  gap: 18px;
  border-radius: 8px;
  padding: 16px;

  &.light {
    border: 0.6px solid #94A3B8;
  }

  &.dark {
    border: 0.6px solid rgba(255, 255, 255, 0.1);
  }
}

.optionsContainer {
  display: flex;
  flex-direction: column;
  padding: 9px 12px;
  border-radius: 4px;
  border: 1px dashed #94A3B8;
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;

  &.light {
    color: #525252;
  }

  &.dark {
    color: #fff;
    opacity: 0.9;
  }

  @media (max-width: break-points.$sm) {
    font-size: 11px;
  }
}

.headContainer {
  display: flex;
  gap: 4px;
  align-items: center;

  @media (max-width: 1300px) {
      flex-direction: column;
      align-items: start;
  }

  &.Adjustment{
    @media (max-width: 1460px) {
      flex-direction: column;
      align-items: start;
    }
  }

  &.Adjustposition {
    flex-direction: column;
    align-items: start;
  }

  @media (max-width: break-points.$sm) {
    flex-direction: column;
    align-items: start;
  }
}

.heading {
  font-size: 12px;
  font-weight: 600;
  line-height: 15px;

  &.light {
    color: #171717;
  }

  &.dark {
    color: #fff;
  }

  @media (max-width: break-points.$sm) {
    font-size: 11px;
  }
}

.countSources {
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;

  &.light {
    color: #171717;
  }

  &.dark {
    color: #fff;
  }

  @media (max-width: break-points.$sm) {
    font-size: 11px;
  }
}

.addSourcesContainer {
  display: flex;
  gap: 10px;
  align-items: center;
  cursor: pointer;
}

.text {
  font-size: 12px;
  font-weight: 600;
  line-height: 15px;

  &.light {
    color: #4338CA;
  }

  &.dark {
    color: #527AE6;
  }

  @media (max-width: break-points.$sm) {
    font-size: 11px;
  }
}

.title {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;

  &.light {
    color: #171717;
  }

  &.dark {
    color: #fff;
  }

  @media (max-width: break-points.$sm) {
    font-size: 14px;
  }
}

.research {
  padding: 4px 8px;
  border-radius: 16px;
  background-color: #DBEAFE;
  font-size: 12px;
  font-weight: 600;
  line-height: 15px;
  color: #2563EB;
  align-items: center;
  display: flex;
  text-align: center;

  @media (max-width: break-points.$sm) {
    font-size: 11px;
  }
}

.mainContainer {
  display: flex;
  gap: 0.5rem;

  @media (max-width: 444px) {
    gap: 3px;
  }
}

.researchContainer {
  display: flex;
  align-items: center;
  gap: 12px;

  @media (max-width: 1300px) {
    flex-direction: column;
    align-items: start;
    gap: 4px;
}

  &.Adjustment{
    @media (max-width: 1460px) {
      flex-direction: column;
    align-items: start;
    gap: 4px;
    }
  }

  &.Adjustposition {
    flex-direction: column;
    align-items: start;
    gap: 4px;
  }

  @media (max-width: break-points.$sm) {
    flex-direction: column;
    align-items: start;
    gap: 4px;
  }
}

.knowledgeWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: break-points.$sm) {
    align-items: start;
  }
}

// .optionsContainer div {
//   display: inline; /* Ensures the quotes are applied inline with the content */
// }
// .optionsContainer::before {
//   content: '"';
// }

// .optionsContainer::after {
//   content: '"';
// }