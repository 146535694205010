@use "../../../../../../scss/break-points";
@use "../../../../../../scss/colors";

.heading {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.headline {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;

  @media (max-width: 576px) {
    font-size: 18px;
    line-height: 24px;
    text-align: justiy;
  }
}

.subHealine {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;

  @media (max-width: 576px) {
    font-size: 14px;
    line-height: 22px;
    text-align: justify;
  }
}

.modalFooter {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 12px;
}

.buttonText {
  &.light {
    color: colors.$textColorLight;
  }

  &.dark {
    color: colors.$white;
  }
}
