@use "../../../../../../scss/break-points";
@use "../../../../../../scss/colors";

.heading {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.headline {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;

  @media (max-width: break-points.$sm) {
    text-align: center;
  }
}

.subHealine {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  opacity: 0.7;

  @media (max-width: break-points.$sm) {
    text-align: center;
  }
}

.modalFooter {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 12px;

  @media (max-width: break-points.$sm) {
    gap: 10px;
  }
}

.buttonText {
  &.light {
    color: colors.$textColorLight;
  }

  &.dark {
    color: colors.$white;
  }
}
