@use "../../../../scss/colors";

.text {
  .light {
    color: colors.$textColorLight;
  }

  .dark {
    color: colors.$white;
  }
}

.setFrequency {
  color: #2A2831;
}

.setFrequencydark {
  color: colors.$darkModeText;
}

.separator {
  .light {
    border-bottom: 1px solid colors.$borderLight;
  }

  .dark {
    border-bottom: 1px solid colors.$borderDark;
  }
}

.Pricecardwhite {
  font-weight: 600;
  border-radius: 20px;
  padding: 20px;
  background-color: #fff;
  color: colors.$textColorLight;

  @media screen and (max-width: 768px) {
    background-color: transparent;
    width: 100%;
    padding: 0px;
  }

  .text {
    .light {
      color: colors.$textColorLight;
    }

    .dark {
      color: colors.$white;
    }
  }
}

.Pricecard {
  border-radius: 20px;
  background-color: #262627;
  color: colors.$white;
  padding: 20px;

  @media screen and (max-width: 768px) {
    background-color: transparent;
    width: 100%;
    padding: 0px;
  }

  .text {
    .light {
      color: colors.$textColorLight;
    }

    .dark {
      color: colors.$white;
    }
  }

}

  .pricelable {
    color:#fff;
  }
  .priceCurrentlabel{
    color:#fff;
  }

.pricelablewhite {
  color: colors.$textColorLight;

}

.lightText {
  color: colors.$textColorLight;
}

.darkText {
  color: colors.$white;
}

.PriceCardLablwhite {
  color: #2A2831;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  /* 216.667% */
  opacity: 0.7;

}
.PriceCardLabl{
  color: #fff;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  /* 216.667% */
  opacity: 0.7;
}

.PriceCardtext {
  color: #5B71F5;
  text-align: right;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
  display: flex;
  align-items: center;
  /* 216.667% */
}

.setSelectedWhite {
  justify-content: space-between;
}

.svg {
  border-top: 1px solid #454646;
  margin-top: 4px;
  margin-bottom: 4px;
}

.creditsDay {
  margin-left: 0.25rem;

  @media screen and (max-width: 331px) {
    margin-left: -1px;
  }
}

.currentPlanCards {
  display: flex;
  padding: 18px 17px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 25px;
  align-self: stretch;
  border-radius: 15px;
  margin-bottom: 20px;
}

.currentdark {
  background: #282828;
}

.currentlight {
  background: #FFF;
}

.btn {
  &.dark {
    @media screen and (max-width: 768px) {
      padding: 10px;
      padding: 10px;
      border-radius: 10px;
      background: #353535;
    }
  }

  &.light {
    @media screen and (max-width: 768px) {
      padding: 10px;
      padding: 10px;
      border-radius: 10px;
      background: #FFF;
      box-shadow: 0px 14px 4px 0px rgba(171, 171, 171, 0.00), 0px 9px 4px 0px rgba(171, 171, 171, 0.01), 0px 5px 3px 0px rgba(171, 171, 171, 0.05), 0px 2px 2px 0px rgba(171, 171, 171, 0.09), 0px 1px 1px 0px rgba(171, 171, 171, 0.10);
    }
  }
}
.lightStatement{
  color: #646464;
}
.darkStatement{
  color: #646464;
}
.landing{
  padding: 18px 4px !important;
}