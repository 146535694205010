@use "../../../scss/colors";

.title {
  margin: 0px auto 10px auto;
  text-align: center;
  font-size: 34px;
  line-height: 54px;
  font-weight: 700;
line-height: normal;
  &.light {
    background: linear-gradient(92.68deg, #4693E8 -41.18%, #6C53FF 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  &.dark {
    background: var(--btn-color, linear-gradient(93deg, #4693E8 -41.18%, #6C53FF 100%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  @media screen and (max-width: 576px) {
    font-size: 34px;
    line-height: 46px;
    max-width: 312px;
  }
  @media screen and (max-width: 405px) {
    font-size: 26px;
    line-height: normal;
  }
  @media screen and (min-width: 769px) {
    font-size: 40px;
  }
  @media screen and (min-width: 1024px) {
    font-size: 60px;
    line-height: normal;
  }
  @media screen and (min-width: 1492px) {
    font-size: 80px;
    line-height: normal;
  }

}

.desc {
  text-align: center;
  line-height: 26px;
  font-size: 18px;      
  padding: 0 20px;
  // max-width: 550px;

  &.light {
    color: colors.$textColorLight;
    opacity: 70%;
  }

  &.dark {
    color:#fff;
    opacity: 0.7;
  }
  @media screen and (max-width: 576px) {
    font-size: 18px;      
     padding: 0 20px;
  }
  @media screen and (max-width: 405px) {
    font-size: 16px;      
     padding: 0 20px;
  }
  @media screen and (min-width: 769px) {
    font-size: 20px;
    max-width: 464px;
    padding: 0;
  }
  @media screen and (min-width: 1024px) {
    font-size: 26px;
    max-width: 603px;
    line-height: normal;
  }
  @media screen and (min-width: 1492px) {
    font-size: 32px;
    max-width: 760px;
    line-height: normal;
  }
}

.pricingSection {
  padding: 80px 15px;
  position: relative;

  
  @media screen and (max-width: 576px) {
    padding: 60px 15px;
  }
  // @media screen and (max-width: 360px) {
  //   padding: 30px 2px;
  // }
  @media screen and (min-width: 769px) {
    padding:100px 2% 100px 2%;
  }
  @media screen and (min-width: 1240px) {
    padding: 100px 1% 100px 1%;
  }

  @media screen and (min-width: 1492px) {
    padding: 100px 0 100px 0;
  }
  &.light {
    background-color: rgba(255, 255, 255, 0.1);
  }

  &.dark {
    background: rgba(42, 40, 49, 0.5);
  }
}

.planTableDark {
  width: 100%;
  white-space: nowrap;
  color: colors.$white;

  thead {
    th {
      width: 16.6%;
    }

    tr {
      th {
        &:not(:first-child) {
          border-top: 1px solid colors.$landingPriceBorderDark;
        }

        &:nth-child(2) {
          border-left: 1px solid colors.$landingPriceBorderDark;
          border-radius: 20px 0 0 0;
        }

        &:last-child {
          border-right: 1px solid colors.$landingPriceBorderDark;
          border-radius: 0 20px 0 0;
        }
      }
    }
  }

  tbody {
    tr {
      td {
        &:first-child {
          border-left: 1px solid colors.$landingPriceBorderDark;
        }

        &:last-child {
          border-right: 1px solid colors.$landingPriceBorderDark;
        }
      }

      &:first-child {
        td {
          border-top: 1px solid colors.$landingPriceBorderDark;

          &:first-child {
            border-radius: 20px 0 0 0;
          }
        }
      }

      &:nth-child(2n + 1) {
        background: #2a2831;
      }

      &:last-child {
        td {
          border-bottom: 1px solid colors.$landingPriceBorderDark;

          &:first-child {
            border-radius: 0 0 0 20px;
          }

          &:last-child {
            border-radius: 0 0 20px 0;
          }
        }
      }
    }
  }
}

.planTableLight {
  width: 100%;
  white-space: nowrap;
  color: colors.$textColorLight;

  thead {
    th {
      width: 16.6%;
    }

    tr {
      th {
        &:not(:first-child) {
          border-top: 1px solid colors.$borderLight;
        }

        &:nth-child(2) {
          border-left: 1px solid colors.$borderLight;
          border-radius: 20px 0 0 0;
        }

        &:last-child {
          border-right: 1px solid colors.$borderLight;
          border-radius: 0 20px 0 0;
        }
      }
    }
  }

  tbody {
    tr {
      td {
        &:first-child {
          border-left: 1px solid colors.$borderLight;
        }

        &:last-child {
          border-right: 1px solid colors.$borderLight;
        }
      }

      &:first-child {
        td {
          border-top: 1px solid colors.$borderLight;

          &:first-child {
            border-radius: 20px 0 0 0;
          }
        }
      }

      &:nth-child(2n) {
        background-color: #f3f5f8;
      }

      &:last-child {
        td {
          border-bottom: 1px solid colors.$borderLight;

          &:first-child {
            border-radius: 0 0 0 20px;
          }

          &:last-child {
            border-radius: 0 0 20px 0;
          }
        }
      }
    }
  }
}
.customBtn{
 display: flex;
 justify-content: center;
 align-items: center;
 text-align: center;
 gap: 1rem;
 @media screen and (max-width: 576px) {
  margin-bottom: 4rem;
}
}

.adjustMargin{
  margin-bottom: 60px;
  @media screen and (min-width: 576px) {
    margin-bottom: 80px;
  }
  @media screen and (min-width: 769px) {
    margin-bottom: 100px;
  }
}