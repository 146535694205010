.container {
    display: flex;
    justify-content: center;
    cursor: pointer;
    gap: 20px;

    @media (max-width: 768px) {
        gap: 8px;
    }
    @media (max-width: 399px) {
        gap: 0px;
    }

    .socialButton {
        background: none;
    }
}