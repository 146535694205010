@use "../../../../../../scss/colors";

.rangeContainer {
  width: 100%;
  padding: 6px 0;

  &.light {
    border-left: 2px solid colors.$borderLight;
    border-right: 2px solid colors.$borderLight;
  }

  &.dark {
    border-left: 2px solid colors.$borderDark;
    border-right: 2px solid colors.$borderDark;
  }
}

.range {
  height: 4px;
  width: 100%;

  &.light {
    background-color: colors.$borderLight;
  }

  &.dark {
    background-color: colors.$borderDark;
  }
}

.rangeValuesContainer {
  width: 100%;
  height: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  opacity: 0.7;
}

.footer {
  padding: 16px 0 0 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 12px;

  &.light {
    border-top: 1px solid rgba(colors.$textColorLight, 0.1);
  }

  &.dark {
    border-top: 1px solid rgba(colors.$white, 0.1);
  }
}
