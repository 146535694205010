@use "../../../../../../scss/colors";

.rolesContainer {
  width: 100%;
  height: 48px;
  border-radius: 10px;
  padding: 4px;
  display: flex;
  gap: 4px;

  &.light {
    background: rgba(42, 40, 49, 0.04);
    color: rgba(colors.$textColorLight, 0.6);

    svg {
      & path {
        stroke: rgba(colors.$textColorLight, 0.6);
      }

      & circle {
        stroke: rgba(colors.$textColorLight, 0.6);
      }

      & ellipse {
        stroke: rgba(colors.$textColorLight, 0.6);
      }
    }
  }

  &.dark {
    background: colors.$stopgenerateDark;
    color: rgba(colors.$white, 0.6);

    svg {
      & path {
        stroke: rgba(colors.$white, 0.6);
      }

      & circle {
        stroke: rgba(colors.$white, 0.6);
      }

      & ellipse {
        stroke: rgba(colors.$white, 0.6);
      }
    }
  }
}

.roleContainer {
  width: 100%;
  height: 100%;
  background: transparent;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &.active {
    background: linear-gradient(92.31deg, colors.$colorLineargrandient 0%, colors.$colorLineargrandient2 100%);
    color: colors.$white;

    svg {
      & path {
        stroke: colors.$white;
      }

      & circle {
        stroke: colors.$white;
      }

      & ellipse {
        stroke: colors.$white;
      }
    }
  }
}

.role {
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  margin-left: 10px;
}

.footer {
  padding: 16px 0 0 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 12px;

  &.light {
    border-top: 1px solid rgba(colors.$textColorLight, 0.1);
  }

  &.dark {
    border-top: 1px solid rgba(colors.$white, 0.1);
  }
}
