@use "../../../../scss/break-points";
@use "../../../../scss/colors";

.textContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.title {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;

    @media (max-width: break-points.$sm) {
        font-size: 11px;
        line-height: 16px;
    }
}

.feature {
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;

    @media (max-width: break-points.$sm) {
        line-height: 16px;
        font-size: 12px;
    }
}

.featureTextRow {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.switchTextContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 16px;

    @media (max-width: break-points.$sm) {
        gap: 10px;
    }
}

.switchText {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 26px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.percentage {
    color: colors.$primaryColor;
}

.planbutton {
    padding-left: 20px !important;
    padding-right: 20px !important;
    height: 44px;

    .text {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: colors.$lightThemeColor;

        @media (max-width: break-points.$sm) {
            font-size: 14px;
            line-height: 20px;
        }
    }
}

.button {
    max-width: 127px;

    @media (max-width: break-points.$sm) {
        max-width: 90px;
    }

    .text {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 28px;
        color: colors.$white;

        @media (max-width: break-points.$sm) {
            font-size: 11px;
            line-height: 22px;
        }
    }
}

.usageTag {
    background-color: colors.$chatSettingUsageTag;
    -moz-background-color: colors.$chatSettingUsageTag;
    border-radius: 300px;
    padding: 2.5px 10px;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 20px;
    color: #fff;
}

.usageTaghead {
    font-size: 10px;
    font-weight: 500;
    background-color: #C3ADFF;
    border-radius: 300px;
    padding: 2.5px 10px;
    margin-left: 0.5rem;
    color: #fff;
}