@use "../../../../scss/colors";

.headline {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;

  &.light {
    color: colors.$textColorLight;
  }

  &.dark {
    color: colors.$white;
  }
  &.memberHeader {
    font-size: 20px;
  }

  @media (max-width: 576px) {
    font-size: 18px;
    line-height: 24px;
    text-align: justify;
  }
}

.btnText {
  &.light {
    color: colors.$textColorLight;
  }

  &.dark {
    color: colors.$white;
  }
}

.btnOKText{
  padding-left: 35px !important;
  padding-right: 35px !important;
}

.modalFooter {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 12px;
}

.freeCredits {
  color: colors.$primaryColor;
  font-family: Raleway;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  cursor: pointer;
  margin: 30px 0 15px 0;
  gap: 8px;

  &.light {
    svg {
      & path {
        fill: colors.$textColorLight;
      }
    }
  }

  &.dark {
    svg {
      & path {
        fill: colors.$white;
      }
    }
  }
}
