@use "../../../../scss/variables";
@use "../../../../scss/colors";
@use "../../../../scss/fonts";
@use "../../../../scss/break-points";

.container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 16px;

  @media (max-width: break-points.$sm) {
    gap: 20px;
    gap: 20px;
  }

  .userInfo {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    margin-top: 60px;

    &.light {
      color: colors.$textColorLightMode;
    }

    &.dark {
      color: #FFF;
    }

    @media (max-width: break-points.$sm) {
      width: 100%;
      margin-top: 0px;
    }

    .userAvatar {
      min-width: 80px;
      min-height: 80px;
      font-size: 24px;
      border-radius: 50%;
      margin-bottom: 16px;

      @media (max-width: break-points.$sm) {
        min-width: 60px;
        min-height: 60px;
        font-size: 18px;
        margin-bottom: 20px;
        margin-top: 20px;
      }
    }

    .userName {
      font-weight: 700;
      font-size: 24px;
      line-height: 30px;
      margin-bottom: 4px;
    }

    .email {
      font-weight: 500;
      font-size: 16px;
      line-height: 26px;

    }

    .teamDDContainer {
      width: 368px;

      @media (max-width: break-points.$sm) {
        width: 100%;
      }
    }
  }

  .row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 24px;
    width: 100%;

    .usageSmall {
      display: none;

      &.lightss {
        color: colors.$textColorLight;
      }

      &.darkss {
        color: #fff;
      }

      @media (max-width: break-points.$sm) {
        display: block;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        left: 0px;
        width: 100%;
      }
    }
 

    @media (max-width: 1050px) {
      flex-direction: column;
      gap: 16px;
    }

    @media (max-width: break-points.$sm) {
      width: 100%;
      display: flex;
      padding: 15px 20px 10px 20px;
      ;
      flex-direction: column;
      align-items: flex-start;
      gap: 0px;
      align-self: stretch;
      border-radius: 10px;

      &.light {
        background: #FBFBFB;
      }

      &.dark {
        background: #282828;
      }
    }

    .col {
      border-radius: 20px;
      width: 368px;
      padding: 20px;
      display: flex;
      align-items: center;
      background-color: rgba(#C5C5C5, 0.04);

      .usageSmall {
        display: none;

        @media (max-width: break-points.$sm) {
          display: block;
          margin-top: 20px;
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
          left: 0px;



        }
      }


      &.lights {
        background-color: rgba(colors.$textColorLightMode, 0.04);
        color: colors.$textColorLight;


        @media (max-width:break-points.$sm) {
          background-color: colors.$darkModeText;
        }
      }

      &.darks {
        background-color: rgba(colors.$darkModeText, 0.08);
        color: colors.$white;


        @media (max-width:break-points.$sm) {
          background-color: colors.$darkModeBackground;
        }
      }

      &.light {
        color: colors.$textColorLightMode;
      }

      &.dark {
        color: colors.$darkModeText;
      }

      @media (max-width: break-points.$sm) {
        background: transparent;
        width: 100%;
        padding: 20px 0px 0px 0px;
      }

      .keyboardIcon {
        width: 50px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: linear-gradient(92.31deg, colors.$colorLineargrandient 0%, colors.$colorLineargrandient2 100%);
        border-radius: 12px;
      }

      .deftgptIcon {
        width: 50px;
        height: 50px;
      }

      .colContent {
        display: flex;
        flex-direction: column;
        margin-left: 16px;

        .title {
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
          margin-bottom: 2px;
        }
      }

      .usage {
        width: 100%;

        .h4 {
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
          margin-bottom: 10px;

          @media (max-width: break-points.$sm) {
            font-size: 14px;
            display: none;
          }
        }

        .svg {
          border-top: 1px solid #454646;
          margin-top: 4px;
          margin-bottom: 4px;
        }

        .usageRow {
          display: flex;
          align-items: center;
          justify-content: space-between;

          @media (max-width: break-points.$sm) {
            margin-bottom: 20px;
          }

          &:first-child {
            margin-bottom: 2px;
          }

          .number {
            font-weight: 600;
            font-size: 14px;
            line-height: 24px;
            font-family: fonts.$regularFont;

            @media (max-width: break-points.$sm) {
              font-size: 14px;
            }
          }
        }
      }

      .language {
        width: 100%;

        .h4 {
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
          margin-bottom: 16px;

          @media (max-width: break-points.$sm) {
            font-size: 14px;
            display: none;
          }
        }

        .select {
          height: 44px;
        }
      }
    }

    .cols {
      border-radius: 20px;
      width: 368px;
      padding: 20px;
      display: flex;
      align-items: center;
      background-color: rgba(#C5C5C5, 0.04);

      @media (max-width:break-points.$sm) {
        padding: 0px;
      }

      .usageSmall {
        display: none;

        @media (max-width: break-points.$sm) {
          display: block;
          margin-top: 20px;
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
          left: 0px;



        }
      }


      &.lights {
        color: colors.$textColorLightMode;


        @media (max-width:break-points.$sm) {
          background-color: colors.$darkModeText;
        }
      }

      &.darks {
        color: colors.$darkModeText;


        @media (max-width:break-points.$sm) {
          background-color: colors.$darkModeBackground;
        }
      }

      &.light {
        background-color: rgba(colors.$textColorLightMode, 0.04);
        color: colors.$textColorLight;
      }

      &.dark {
        background-color: rgba(colors.$darkModeText, 0.08);
        color: colors.$white;
      }

      @media (max-width: break-points.$sm) {
        background: transparent;
        width: 100%;
        padding: 10px 10;
      }

      .keyboardIcon {
        width: 50px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: linear-gradient(92.31deg, colors.$colorLineargrandient 0%, colors.$colorLineargrandient2 100%);
        border-radius: 12px;
      }

      .deftgptIcon {
        width: 50px;
        height: 50px;
      }

      .colContent {
        display: flex;
        flex-direction: column;
        margin-left: 16px;

        .title {
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
          margin-bottom: 2px;
        }
      }

      .usage {
        width: 100%;

        .h4 {
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
          margin-bottom: 10px;

          @media (max-width: break-points.$sm) {
            font-size: 14px;
            display: none;
          }
        }

        .svg {
          display: none;

          @media (max-width:break-points.$sm) {
            display: block;

          }
        }

        .usageRow {
          display: flex;
          align-items: center;
          justify-content: space-between;

          @media (max-width: break-points.$sm) {
            margin-bottom: 20px;
          }

          &:first-child {
            margin-bottom: 2px;
          }

          .number {
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            font-family: fonts.$regularFont;

            @media (max-width: break-points.$sm) {
              font-size: 14px;
            }
          }
        }
      }

      .language {
        width: 100%;

        .h4 {
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
          margin-bottom: 16px;

          @media (max-width: break-points.$sm) {
            font-size: 14px;
            display: none;
          }
        }

        .select {
          height: 44px;
        }
      }
    }
  }

  .plan {
    border-radius: 20px;
    width: 760px;
    height: 90px;
    padding: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    &.light {
      color: colors.$textColorLightMode;
    }

    &.dark {
      color: colors.$darkModeText;
    }

    @media (max-width: 1050px) {
      width: 368px;
      height: auto;
      flex-direction: column;
      gap: 16px;
    }

    @media (max-width: break-points.$sm) {
      width: 100%;
      padding: 15px 20px 10px 20px;

      &.light {
        background: #FBFBFB;
      }

      &.dark {
        background: #282828;
      }
    }

    .planbox {
      padding: 19px 16px;
      background: rgba(124, 77, 255, 0.1);
      border-radius: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;

      @media (max-width: 1050px) {
        width: 100%;
      }

      @media (max-width: break-points.$sm) {
        width: auto;
        padding: 10px 12px;
      }

      .text {
        font-weight: 500;
        font-size: 15px;
        line-height: 24px;
        color: colors.$primaryColor;

        &.light {
          opacity: 1;
        }

        &.dark {
          opacity: 1;
        }

        @media (max-width: break-points.$sm) {
          opacity: 1;
          font-size: 16px;
          font-weight:600;
          font-size: 16px;
          font-weight:600;
        }
      }

      .count {
        font-size: 22px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        display: inline-block;
        margin: 0 4px;

        @media (max-width: break-points.$sm) {
          margin: 0;
        }
      }
    }

    .planTypeContainer {
      width: 368px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      @media (max-width: break-points.$lg) {
        width: 100%;
        padding: 0 12px 10px;
      }

      .playType {
        display: flex;
        flex-direction: column;
        @media (max-width: break-points.$sm) {
          width: 100%;
        }
        .planValue {
          display: inline-block;
          margin-top: 4px;
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
          text-transform: uppercase;
          @media (max-width: break-points.$sm) {
            margin-top: 20px;
                    }
        }
      }

      .upgradeButton {
        border-radius: 10px;
        background:  linear-gradient(93deg, #4693E8 -41.18%, #6C53FF 100%);
        background:  linear-gradient(93deg, #4693E8 -41.18%, #6C53FF 100%);
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 10px 16px 10px 14px;
        gap: 10px;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        @media (max-width: break-points.$sm) {
        width: 100%;
        }

        &.light {
          color: colors.$darkModeText;
        }

        &.dark {
          color: colors.$textColorLightMode;
        }

        &:hover {
          cursor: pointer;
          background: linear-gradient(92.31deg, colors.$colorLineargrandient2 0%, colors.$colorLineargrandient 100%);
        }
      }

      @media (max-width: break-points.$sm) {
        width: 100%;
        display: flex;
        padding: 0px;
        flex-direction: column;
        align-items: flex-start;
        gap: 20px;
        align-self: stretch;
      }
    }
  }
}

// Account
.emailContainer {
  position: relative;
  display: flex;
  align-items: center;
  width: 95%;
  justify-content: space-between;

  .dropdown {
    position: relative;
    display: inline-block;
  }
}

.menuItemEdit {
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  display: flex;
  gap: 1.4rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0 16px;

  &.light {
    color: #2A2831;
  }

  &.dark {
    color: #FFF;
  }
}

.menuItemdeactivate {
  padding: 0 12px;

}

.menuItemDel {
  color: #FF4B4B;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  display: flex;
  gap: 1.4rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0 10px;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  display: flex;
  gap: 1.4rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0 15.5px;

  .icon {
    margin-right: 8px;
  }
}

.rc-dropdown-hidden {
  display: none !important;
}

.rc-dropdown {
  display: block !important;
}

.DropDownbody {
  width: 100%;
  display: flex;
  flex-direction: column;
  max-height: 180px;
  overflow-y: auto;
}

.accountSVG {
  border-top: 1px solid #ccc;
}

.smallScreenAdjustment{
  top: 29px;
  right: 0px !important;
}

.account {
  font-family: Raleway;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;

  &.light {
    color: #2A2831;
  }

  &.dark {
    color: #FFF;
  }
}

.nameSeperator {
  margin: 10px 0px;
  width: 95%;
  height: 1px;

  &.light {
    background: #F7F7F7;
  }

  &.dark {
    background: #272729;
  }
}

.optionsIcon {
  svg{
  width: 22px;
  height: 23px;
  }
}

.displaylang {
  display: none;

  &.lightss {
    color: #2A2831;
  }

  &.darkss {
    color: #FFF
  }

  @media (max-width: break-points.$sm) {
    display: block;
    font-size: 16px;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    margin: 10px 10px 10px 30px;
    width: 100%;


  }
}

.accountCols {
  width: 100%;
  display: flex;
  align-items: center;
}

.language {
  width: 100%;

  .h4 {
    @media (max-width: break-points.$sm) {
      font-size: 14px;
      display: none;
    }
  }
}

.select {
  @media (max-width: break-points.$sm) {
    padding: 0px 10px;
  }
}

.organization {
  font-size: 16px;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  margin: 10px 10px 10px 30px;
  width: 100%;

  &.light {
    color: #2A2831;
  }

  &.dark {
    color: #FFF;
  }
}

.usageSeperator {
  margin-top: 5px;
  width: 100%;
  height: 1px;

  &.light {
    background: #DFDFDF !important;
  }

  &.dark {
    background: #DFDFDF !important;
  }
}
.line{
  width: 50%;
height: 6px;
flex-shrink: 0;
border-radius: 5px;
&.light{
  background: #000;
}
&.dark{
background: #FFF;
}
}