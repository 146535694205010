@use "../../../scss/colors";
@use "../../../scss/break-points";

.section {
  min-height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 100px 5% 15px;
    gap: 30px;
    @media screen and (max-width: 576px) {
      padding: 70px 7% 15px;
      gap: 60px;
    }

  @media screen and (min-width: 797px) {
    padding: 125px 15px 0px;
    gap: 50px;
  }
  @media screen and (min-width: 1225px) {
    padding: 172px 15px 15px;
    gap: 50px;
  }
  @media screen and (min-width: 1492px) {
    padding: 192px 15px 15px;
  }

  &.light {
    background-color: rgba(255, 255, 255, 0.1);
  }

  &.dark {
    background: rgba(42, 40, 49, 0.5);
  }
}

.left {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  gap: 20px;

  @media screen and (min-width: 1024px) {
    max-width: 60%;
    gap: 20px;
  }
}

.h1 {
  font-weight: 700;
  font-size: 35px;
  line-height: 34px;


  &.light {
    color: colors.$textColorLight;
  }

  &.dark {
    color:#fff;
  }
  @media screen and (max-width: 576px) {
    font-size: 34px;
    line-height: 54px;
}
@media screen and (max-width: 405px) {
  font-size: 26px;
  line-height: normal;
}
  @media screen and (min-width: 1024px) {
    font-size: 60px;
    line-height: 80px;
  }
  @media screen and (min-width: 1492px) {
    font-size: 80px;
    line-height: normal;
  }
}

.subtitle {
  font-size: 20px;
  line-height: 28px;
  margin-top: 20px;

  @media (max-width: 576px) {
    font-size: 26px;
    margin-top: 20px;
    line-height: 37px;
    font-weight: 700;
    width: 340px;
  }
  @media screen and (max-width: 405px) {
    font-size: 21px;
    line-height: 30px;
    width: 256px;
  }
  @media screen and (min-width: 1024px) {
    font-size: 34px;
    line-height: 48px;
    margin-top: 20px;
  }
  @media screen and (min-width: 1492px) {
    font-size: 46px;
    line-height: normal;
  }
}

.text {
  line-height: 24px;
  font-weight: 400;
  font-size: 16px;
  padding: 0 14px;
  text-align: center;

  &.light {
    color: colors.$textColorLight;
    opacity: 70%;
  }

  &.dark {
    color: #fff;
    opacity: 0.7;
  }
  @media screen and (max-width: 576px) {
    text-align: center;
    line-height: 26px;
    font-size: 18px;
    padding: 0 19px;
  }
  @media screen and (max-width: 405px) {
    font-size: 16px;
    padding: 0 23px;
  }
  @media screen and (min-width: 769px) {
    padding: 0 8%;
  }

  @media screen and (min-width: 1024px) {
    font-size: 17px;
    padding: 0;
    max-width: 583px;
  }
  @media screen and (min-width: 1492px) {
    font-size: 22px;
    padding: 0;
    max-width: 766px;
  }
}

.xs_image {
  width: 90% !important;
  margin-bottom: 34px;
}

.lg_image {
  position: relative;
  height: 180px;
  margin-left: 0px;

  @media screen and (max-width: 380px) {
   margin-top: -20px;
  }

  @media screen and (min-width: 420px) {
    display: block;
    height: 215px;
    margin-left: 0px;
  }
  @media screen and (min-width: 576px) {
    display: block;
    height: 320px;
    margin-left: 0px;
  }
  @media screen and (min-width: 797px) {
    height: 425px;
    margin-left: 0px;
  }
  @media screen and (min-width: 1240px) {
    display: block;
    height: 500px;
    margin-left: 0px;
  }
  @media screen and (min-width: 1440px) {
    display: block;
    height: 500px;
    margin-left: 34px;
  }
}


.imgBox {
  width: 780px;
  height: 455px;
  display: none;
  align-items: center;
  justify-content: center;

  @media screen and (min-width: 1024px) {
    display: flex;
  }
}

.bigImage {
  position: absolute;
  right: 0; 
  z-index: 1;
}

.bg {
  position: relative;
  left: -50px;
  top: -50px;
  opacity: 0.4;
  width: 821px;
  height: 821px;
  background: radial-gradient(
    50% 50% at 50% 50%,
    #5200ff 0%,
    rgba(20, 21, 23, 0) 100%
  );
}

.secondary {
  width: 360px;
  height: 360px;
  position: absolute;
  left: 0px;
  bottom: -36px;
  opacity: 0.4;
  z-index: -1;
  background: radial-gradient(
    50% 50% at 50% 50%,
    #0001bb 0%,
    rgba(20, 21, 23, 0) 100%
  );
}

.flower {
  position: absolute;
  right: -50px;
  bottom: -140px;
  z-index: 2;
}

.flowerImage {
  animation: spin 5s linear infinite;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

@media screen and (min-width: 1024px) {
  .left {
    justify-content: center;
  }

  .rightSection {
    display: block;
  }
}

.gradient {
  background: linear-gradient(92.68deg, #4693E8 -41.18%, #6C53FF 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    
}
.disablebr {
  @media (max-width: break-points.$sm) {
    display: none;
  }
}
.allowbr {
  display: none;
  @media (max-width: break-points.$sm) {
    display: block;
  }
}
