@use "../../../../../scss/variables";
@use "../../../../../scss/break-points";
@use "../../../../../scss/colors";

.container {
  width: 100%;
  height: inherit;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  &.light {
    color: colors.$textColorLight;
  }

  &.dark {
    color: colors.$white;
  }

  .logoutButton {
    padding: 8px 15px;
    border-radius: 10px;
    background-color: transparent;

    &.dark {
      background-color: colors.$darkModeSetting;
    }

    .btnContent {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 12px;
      opacity: 0.9;

      &.light {
        color: colors.$textColorLightMode;

        svg {
          & path {
            stroke: colors.$textColorLightMode;
          }
        }
      }

      &.dark {
        color: colors.$darkModeText;

        svg {
          & path {
            stroke: colors.$darkModeText;
          }
        }
      }

      .text {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;

        @media (max-width: break-points.$sm) {
          font-size: 13px;
          line-height: 18px;
        }
      }

      .icon {
        width: 22px;
        height: 22px;

        @media (max-width: break-points.$sm) {
          width: 18px;
          height: 18px;
        }
      }
    }
  }
}
