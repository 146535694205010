@use "../../../scss/colors";

.switchButton {
  width: 60px;
  height: 32px;
  padding: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  border-radius: 300px;
  border: 1px solid rgba(255, 255, 255, 0.05);
  margin-bottom: -1px;
  z-index: 20;

  &.dark {
    border: 1px solid rgba(255, 255, 255, 0.05);
    background: #212121;
  }

  &.SidebarDark {
    background: #2A274A;
  }

  &.headtheme {
    border-radius: 300px;
    border: var(--sds-size-stroke-border) solid rgba(255, 255, 255, 0.05);
    background: #212121;
  }

  &.light {
    background: #E2E9FF;
  }

  &.chatside {
    position: absolute;
    right: 0;
  }

  &.chatSidebatlight {
    border: 1px solid rgba(255, 255, 255, 0.05);
    background: #F9F9F9;
  }

  .state {
    min-width: 24px;
    min-height: 24px;
    border-radius: 300px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    svg {
      & path {
        stroke: #71717a;
      }
    }

    &.SidebarDark {
      svg {
        & path {
          stroke: colors.$primaryColor;
        }
      }
    }

    &.isActiveLight {
      background-color: colors.$white;
      border: 1px solid rgba(colors.$darkModeText, 0.05);
      box-shadow: 0px 1px 2px 0px rgba(91, 104, 113, 0.32),
        0px 0px 1px 0px rgba(26, 32, 36, 0.32);

      svg {
        & path {
          stroke: #5B71F5;
        }
      }

    }

    &.chatSidebatlight{
      svg {
        & path {
          stroke: #7C4DFF;
        }
      }
    }

    &.isActiveDark {
      background-color: #3D3D4B;


      svg {
        & path {
          stroke: #5B71F5;
        }
      }

      &.SidebarDark {
        background: #7C4DFF;

        svg {
          & path {
            stroke: colors.$white;
          }
        }
      }

    }

    &.headtheme {
      background: var(--Background-Default-Default, #FFF);
      border: 1px solid rgba(colors.$darkModeText, 0.05);
      box-shadow: 0px 1px 2px 0px rgba(91, 104, 113, 0.32),
        0px 0px 1px 0px rgba(26, 32, 36, 0.32);

      svg {
        & path {
          stroke: #5B71F5;
        }
      }
    }

  }
}