@use "../../../../scss/break-points";
@use '../../../../scss/colors';

.mainContainer{

  &.light{
  @media (max-width:862px) {
    background-color: #fff;
    padding: 0px 8px 12px 8px;
    border-radius: 10px;
  }
  @media (max-width:break-points.$md) {
    background-color: #fff;
    padding: 0px 0px 12px 0px;
    border-radius: 10px;
  }
}
&.dark{
  @media (max-width: 862px) {
    background-color: #252526;
    padding: 0px 8px 12px 8px;
    border-radius: 10px;
  }
  @media (max-width:break-points.$md) {
    background-color: #252526;
    padding: 0px 0px 12px 0px;
    border-radius: 10px;
  }
}
}

.tableContainer {
  padding: 18px;
  border-radius: 24px;
  margin-left: 10px;



  &.isLoading {
    width: 100%;
    height: 100%;
  }

  &.light {
    background-color: rgba(colors.$textColorLightMode, 0.04);
    @media (max-width: 862px) {
      background-color: #fff;
    }
  }

  &.dark {
    background-color: colors.$darkModeSetting;
    @media (max-width: 862px) {
      background-color: transparent;
    }
  }
  
  @media (max-width: 862px) {
    padding: 0px;
    border-radius: 0px;
    margin-left: 0px;
  }

}
.line{
  width: 50%;
height: 6px;
flex-shrink: 0;
border-radius: 5px;
margin-left: 25%;
bottom: 15px;
position: fixed;
&.light{
  background: #000;
}
&.dark{
background: #FFF;
}
}
.separator{
  margin-top: 20px;
}