@use "../../../../../../scss/break-points";
@use "../../../../../../scss/colors";

.stopButton {
  border-radius: 300px;
  padding: 7px 8px 7px 12px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
  top: -62px;
  position: absolute;
  left: 50%;
  z-index: 9997;
  transform: translateX(-50%);

  &.light {
    border: 1px solid colors.$borderLight;
    color: colors.$textColorLight;
    background-color: colors.$stopgenerateLight;

    svg {
      & path {
        fill: colors.$textColorLight;
      }
    }
  }

  &.dark {
    border: 1px solid colors.$primaryColor;
    color: colors.$primaryColor;
    background-color: colors.$stopgenerateDark;

    svg {
      & path {
        fill: colors.$primaryColor;
      }
    }
  }

  @media (max-width: break-points.$sm) {
    padding: 6px 7px 6px 10px;
    font-size: 12px;
    gap: 4px;
  }

  .stopIcon {
    width: 16px;
    height: 16px;

    @media (max-width: break-points.$sm) {
      width: 13px;
      height: 13px;
    }
  }
}
