@use '../../.../../../../../../../src/scss/colors';
@use '../../.../../../../../../../src/scss/break-points';

.textarea {
    width: 100%;
    resize: none;
    background-color: transparent;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    border-radius: 10px;
    height: 24px;
    padding: 16px;
    white-space: pre-line;

    &.light {
        color: colors.$textColorLight;
        border: 1px solid colors.$promptborder;

        &::placeholder {
            color: colors.$textColorLightMode;
            opacity: 0.6;
        }
    }

    &.dark {
        color: colors.$white;
        border: 1px solid rgba(colors.$darkModeText, 0.1);

        &::placeholder {
            color: colors.$darkModeText;
            opacity: 0.6;
        }
    }

    &.error {
        border-color: transparent;
        border-color: colors.$red;
    }

    @media (max-width: break-points.$sm) {
        font-size: 14px;
    }

    &.activeborder {
        border-color: #527AE6;
    }
    &.activatedborder{
        border-color: #527AE6;
    }
}

.CustomButtonPrompt {
    padding: 10px 40px !important;
}

.activeborder {
    border-bottom: 2px solid;
}
.activatedborder{
    border-bottom: 2px solid;
    padding: 0px;
}
.darkBorder{
    border-bottom: 2px solid #CBCBDD !important;
}

.lightBorder{
    border-color: colors.$darkModeText;
}
.mainPrompt{
    @media (max-width: 335px) {
    gap: 0px;
         }
}
.Prompt{
    @media (max-width: 355px) {
        margin-left: -6px;
         }
}
.addPrompt{
    @media (max-width: 425px) {
    font-size: 14px;
    }
}
.promptBtn{
    @media (max-width: 429px) {
        font-size: 13px;
    }
    @media (max-width: 355px) {
        font-size: 12px;
    }
}
.modal{
    @media (max-width: 385px) {
        padding-left: 20px;
        padding-right: 20px;
     }
    @media (max-width: 365px) {
       padding-left: 13px;
       padding-right: 13px;
    }
}
.Community{
    @media (max-width: 429px) {
        font-size: 13px;
        line-height: 18px;
    }
    @media (max-width: 355px) {
        font-size: 12px;
    }
}
.arrowIcon{
    @media (max-width: break-points.$sm) {
       svg{
        width: 17px;
        height: 18px;
       }
    }
}
.edit{
    @media (max-width: break-points.$sm) {
       svg{
        width: 16px;
        height: 17px;
       }
    }
}
.delete{
    @media (max-width: break-points.$sm) {
       svg{
        width: 16px;
        height: 17px;
       }
    }
}
.lightTitle{
    color: #2A2831;
    opacity: 1;
}
.darkTtile{
    color: #FFF;
    opacity: 1;
}
.lightHeading{
    color: #2A2831;
    opacity: 0.6;
}
.darkHeading{
    color: #FFF;
    opacity: 0.6;
}