@use '../../../../../../scss/break-points';
@use '../../../../../../scss/variables';
@use '../../../../../../scss/colors';

.IGOptionContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    transition: padding variables.$sb-transition ease;
  
    &.light {
      color: #527AE6;
    }
    &.isPrivateChat{
      color: colors.$textColorLightMode;
  }
    &.dark {
      color: #527AE6;

    }
  
    &.isSidebarOpen {
      transition: padding variables.$sb-transition ease;

    }
  
  
    .IGOptions {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 15px;
  
      @media (max-width: break-points.$sm) {
        gap: 10px;
      }
    }
  
    .imageCredits {
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 28px;
  
      &.light{
        background: rgb(255, 255, 255);
        padding: 0px 3px;
      }
      &.dark{
        background-color: #181819;
        padding: 0px 3px;
      }
      @media (max-width: break-points.$sm) {
        font-size: 10px;
      }
    }
  }