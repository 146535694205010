@use "../../../../scss/break-points";
.container {
    border-radius: 6px;
    padding: 12px;
    gap: 18px;
    display: flex;
    flex-direction: column;
    &.light {
        background-color: #fff;
        border: 0.5px solid #94A3B8;
    }

    &.dark {
        background-color: #252526;
        border: 0.5px solid rgba(255, 255, 255, 0.5);
    }
}
.addSources{
    gap: 14px;
    display: flex;
    flex-direction: column;
}
.headContainer {
    display: flex;
    gap: 8px;
}

.heading {
    font-size: 12px;
    font-weight: 600;
    line-height: 15px;
    &.light {
        color: #171717;
    }

    &.dark {
        color: #fff;
    }
    @media (max-width: break-points.$sm) {
        font-size: 11px;
      }
}
.subheading {
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    &.light {
        color: #171717;
    }

    &.dark {
        color: #fff;
    }
    @media (max-width: break-points.$sm) {
        font-size: 11px;
      }
}
.filesContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 8px;
    border-radius: 4px;
    &.light {
        border: 0.2px solid rgba(148, 163, 184, 0.5);
        box-shadow: 0px 1px 2px 0px #0000001A;
    }

    &.dark {
        border: 0.2px solid rgba(255, 255, 255, 0.5);
        box-shadow: 0px 1px 2px 0px #ffffff1a;
    }
}

.fileWrapper {
    display: flex;
    gap: 15px;
    align-items: center;
}

.iconContainer {
    height: 38px;
    width: 38px;
    border-radius: 4px;
    background-color: #DBEAFE;
    display: flex;
    align-items: center;
    justify-content: center;
}

.fileNameWrapper {
    display: flex;
    gap: 6px;
    flex-direction: column;
    width: 100%;

}

.fileName {
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    &.light {
        color: #171717;
    }
    &.dark {
        color: #fff;
    }
    min-width: 120px; /* Ensures the name has some space */
    max-width: 300px; /* Prevents it from taking too much */
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; /* Keeps the text in a single line */
    width: 60%;
    @media (max-width: break-points.$sm) {
        font-size: 12px;
      }
}

.uploadingContainer {
    display: flex;
    gap: 8px;
    align-items: center;
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    &.light {
        color: #2563EB;
    }
    &.dark {
        color: #527AE6;
    }
    @media (max-width: break-points.$sm) {
        font-size: 11px;
      }
}

.icons {
    cursor: pointer;
    flex-shrink: 0;
}
.conversationIcon{
    height: 38px;
    width: 38px;
    border-radius: 4px;
    background-color: #E2E8F0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.errorContainer{
        height: 38px;
        width: 38px;
        border-radius: 4px;
        background-color: #FEE2E2;
        display: flex;
        align-items: center;
        justify-content: center;
}