@use "../../../../scss/colors";

.container {
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 10%;
  padding-right: 10%;
  text-align: center;
  font-family: Raleway;
  font-size: 21px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;

  &.light {
    color: colors.$textColorLight;
  }

  &.dark {
    color: colors.$white;
  }

  .title {
    font-size: 24px;
    margin-bottom: 30px;
    font-weight: bold;
  }

  @media (max-width: 768px) {
    padding-left: 5%;
    padding-right: 5%;
  }

  .title {
    width: 80%;
    font-size: 24px;
    margin-bottom: 30px;
    margin-left: auto;
    margin-right: auto;
    font-weight: bold;

    @media (max-width: 768px) {
      width: 100%;
      margin-left: 0;
      margin-right: 0;
    }
  }

  .lableInput {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 70%;
    gap: 30px;
    margin: 0 auto;

    @media (max-width: 768px) {
      width: 90%;
    }
  }
}
