@use "../../../../../../scss/colors";
@use "../../../../../../scss/break-points";

.scrollButton {
  width: 38px;
  height: 38px;
  top: -62px;
  position: absolute;
  right: 40px;
  z-index: 9997;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &.light {
    border: 1px solid colors.$borderLight;
    background-color: colors.$stopgenerateLight;

    svg {
      & path {
        fill: colors.$textColorLight;
      }
    }
  }

  &.dark {
    border: 1px solid colors.$borderDark;
    background-color: colors.$scrollDarkMode;

    svg {
      & path {
        fill: colors.$white;
      }
    }
  }

  @media (max-width: break-points.$lg) {
    right: 20px;
  }

  @media (max-width: break-points.$sm) {
    width: 28px;
    height: 28px;
    right: 15px;
  }
}
