@use '../../../../../../scss/variables';
@use '../../../../../../scss/break-points';
@use "../../../../../../scss/colors";

.regenerateOptions{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .IGOptionsContainer {
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 4px 9px;
    transition: padding variables.$sb-transition ease;

    &.light {
      color: colors.$textColorLightMode;
      border-top: 1px solid #EFEFEF;
    }

    &.dark {
      color: colors.$white;
      border-top: 1px solid #303031;
      padding: 4px 6px 4px 13px;
    }

    &.isSidebarOpen {
      padding: 4px 9px;
      transition: padding variables.$sb-transition ease;
    }

    @media (max-width: break-points.$sm) {
      top: 12px;
      // padding: 15px;
      padding: 6px 15px;
    }

    .IGOptions {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 15px;

      @media (max-width: break-points.$sm) {
        gap: 0px;
      }

      .option {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 7px;

        @media (max-width: break-points.$sm) {
          gap: 5px;
        }

        .label {
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 28px;

          @media (max-width: break-points.$sm) {
            font-size: 12px;
          }

          @media (max-width: 440px) {
            display: none;
          }
        }

        .regenerateLabel{
          font-family: Raleway;
          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          line-height: 28px;
          @media (max-width: break-points.$sm) {
            font-size: 10px;
          }
        }
      }
    }

    .imageCredits {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px;

      &.light {
        color: #527AE6;
        opacity: 0.9;
      }

      &.dark {
        color: #527AE6;

      }

      @media (max-width: break-points.$sm) {
        font-size: 11px;
        padding: 0;
      }
    }
  }

  .regenerateLabel{
    font-family: Raleway;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    font-size: 10px;
  }

