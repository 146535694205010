@use "../../../../../scss/colors";

.header {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}

.headerTextContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.crossBtn {
  background-color: transparent;
  cursor: pointer;
}

.title {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  @media (max-width: 576px) {
    font-size:14px;
  }
}

.description {
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
  opacity: 0.7;
  display: inline-block;
}

.buttonText {
  &.light {
    color: colors.$textColorLight;
  }

  &.dark {
    color: colors.$white;
  }
}
.emailConfirmation{
  display: flex;
  flex-direction: column;
  gap: 51px;
  align-items: center;
  justify-content: center;
  text-align: center;
 padding: 0 95px;
 @media (max-width: 1250px) {
  gap:38px;
  padding: 0 66px;
  svg {
     width: 166px;
     height: 165px;
  }
}
@media (max-width: 720px) {
  padding: 0 44px;
}
@media (max-width: 576px) {
  gap:22px;
  padding: 0 18px;
  svg {
     width: 120px;
     height: 119px;
  }
}
@media (max-width: 410px) {
  gap:20px;
  padding: 0px;
  svg {
     width: 120px;
     height: 119px;
  }
}
}
.confirmationTitle{
  color: #2A2831;
text-align: center;
font-family: Raleway;
font-size: 36px;
font-style: normal;
font-weight: 600;
line-height: 24px;
&.light{
  color: #2A2831;
}
&.dark{
  color: rgba(255, 255, 255, 1);
}
@media (max-width: 1250px) {
  font-size: 30px;
}
@media (max-width: 576px) {
  font-size: 23px;
}
}
.confirmationText{
  color: #71717A;
text-align: center;
font-family: Raleway;
font-size: 18px;
font-style: normal;
font-weight: 600;
line-height: 24px; 
&.light{
  color: #71717A;
}
&.dark{
  color: rgba(255, 255, 255, 0.8);
}
@media (max-width: 1250px) {
  font-size: 16px;
}
@media (max-width: 576px) {
  font-size: 12px;
}
}
.highlightedEmail{
font-family: Raleway;
font-size: 18px;
font-style: normal;
font-weight: 600;
line-height: 24px;
text-decoration-line: underline;
text-decoration-style: solid;
text-decoration-skip-ink: auto;
text-decoration-thickness: auto;
text-underline-offset: auto;
text-underline-position: from-font;
&.light{
  color: #71717A;
}
&.dark{
  color: rgba(255, 255, 255, 0.8);
}
@media (max-width: 1250px) {
  font-size: 16px;
}
@media (max-width: 576px) {
  font-size: 13px;
}
}