$pagePadding: 30px;
$sidebar: 280px;
$miniSidebar: 110px;
$sidebar-header-height: 45px;
$navbar: 70px;
$md-navbar: 50px;
$IGChatFooter: 95px;
$chatFooter: 75px;
$chatFooterSM: 65px;
$IGChatFooterSM: 65px;
$transition: 0.3s;
$sb-transition: 0.5s;
$transition-out: all $transition ease-out;
$sidebar-box: 250px;
$minisidebar-box: 80px;
$sidebaropen: 290px;
$miniSidebaropen: 120px;
$IChatFooter: 76px;

$border-thin: 1px;
$border-thick: 2px;

$border-radius-xs: 4px;
$border-radius-sm: 8px;
$border-radius-md: 10px;
$border-radius-lg: 15px;

$spacing-xs: 3px;
$spacing-sm: 5px;
$spacing-md: 10px;
$spacing-lg: 15px;
$spacing-xl: 20px;
$spacing-2xl: 40px;
$spacing-page-bottom: 150px;
$main-page-content-min-width: 960px;

$width-size-menu: 93px;

$width-modal-xs: 346px;
$width-modal-sm: 492px;
$width-modal-md: 585px;
$width-modal-lg: 940px;
$width-modal-xl: 1008px;
$width-modal-error: 666px;
$width-modal-workSpace: 517px;

