@use "../../scss/colors";

@keyframes spin {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(359deg);
  }
}

.spinner {
  width: 42px;
  height: 42px;
  border: 4px solid #527AE6;
  border-top: 4px solid transparent;
  border-radius: 50%;
  animation: spin 1.5s linear 0s infinite;

  &.isBlack {
    border: 4px solid colors.$black;
    border-top: 4px solid transparent;
  }

}

.small {
  width: 30px;
  height: 30px;
  border: 3px solid #527AE6;
  border-top: 3px solid transparent;

  &.isBlack {
    border: 3px solid colors.$black;
    border-top: 3px solid transparent;
  }

  &.isWhite {
    border: 3px solid colors.$darkModeText;
    border-top: 3px solid transparent;
  }
}

.extraSmall {
  width: 15px;
  height: 15px;
  border: 2px solid #527AE6;
  border-top: 2px solid transparent;

  &.isBlack {
    border: 2px solid colors.$black;
    border-top: 2px solid transparent;
  }

  &.isWhite {
    border: 2px solid colors.$darkModeText;
    border-top: 2px solid transparent;
  }

  &.deleteFile{
    border: 2px solid #527AE6;
    border-top: 2px solid transparent;
  }
}
