@use "sass:math";
@use "../../../scss/colors";
@use "../../../scss/variables";

$line-height: 17px;
$icon-size: 14px;
$icon-top: math.div($line-height - $icon-size, 2);

.container {
  display: inline-block;
  margin-left: variables.$spacing-sm;
  color: colors.$grey;
  width: $icon-size;
}

.icon {
  position: absolute;
  top: $icon-top;
}
