@use "../../../scss/colors";
@use "../../../scss/variables";
@use "../../../scss/z-indices";
@use "../../../scss/break-points";

.container {
  display: inline;
  position: relative;
}

.tooltip {
  position: absolute;
  font-size: 12px;
  line-height: initial;
  padding: variables.$spacing-md;
  border-radius: 5px;
  max-width: 300px;
  z-index: z-indices.$tooltip;
  box-shadow: 0 2px 4px rgba(colors.$dark-blue, 0.12);
  background: colors.$themeActiveDark;
  color: colors.$white;
  text-align: justify;
  word-break: break-word;

  a {
    color: rgba(colors.$white, 0.5);
  }

  &.light {
    background: rgba(colors.$white, 0.9);
    color: colors.$black;

    a {
      color: colors.$blue;
    }
  }

  &.prompt {
    position: relative;
    border-radius: 0.4em;
    padding: 10px 20px;
    display: inline-block;
    color: #527AE6;
    background: #EDF1FE;
    padding: variables.$spacing-md;
    margin-left: 346px;
    margin-top: -20px;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;

    @media (max-width: break-points.$lg) {
      margin-left: 285px;
      font-size: 14px;
    }

  }

  &.prompt::after {
    content: '';
    position: absolute;
    bottom: -15px;
    /* Adjust the position to match your design */
    left: 28px;
    /* Adjust the position to match your design */
    width: 0;
    height: 0;
    border: 15px solid transparent;
    border-top-color: #EDF1FE;
    border-bottom: 0;
    margin-left: -15px;
  }

  &.promptDark {
    position: relative;
    background: #252526;
    border-radius: 0.4em;
    padding: 10px 20px;
    display: inline-block;
    color: colors.$darkModeText;
    padding: variables.$spacing-md;
    margin-left: 346px;
    margin-top: -20px;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;

    @media (max-width: break-points.$lg) {
      margin-left: 285px;
      font-size: 14px;
    }

  }
  &.promptOpen {
    margin-left: 176px;

    @media (max-width: break-points.$lg) {
      margin-left: 116px;
      font-size: 14px;
    }

    @media (max-width: break-points.$md) {
      margin-left: 36px;
    }

    @media (max-width: break-points.$sm) {
      margin-left: 17px;
      font-size: 12px;
    }

    @media (max-width: 390px) {
      margin-left: 13px;
    }
  }
  &.customize {
    @media (max-width: break-points.$md) {
      position: fixed;
      left: 82px;
    margin-left: 0px;
    }
    @media (max-width: break-points.$sm) {
      position: fixed;
      left: 46px;
    margin-left: 0px;
    }
  }

  &.imageGeneration {
    margin-left: 0px;
    left: 93.5%;

    @media (max-width: 1920px) {
      left: 91.5%;
    }

    @media (max-width: 1680px) {
      left: 90%;
    }

    @media (max-width: 1440px) {
      left: 88.5%;
    }

    @media (max-width: 1280px) {
      left: 87%;
    }

    @media (max-width: 1160px) {
      left: 86%;
    }

    @media (max-width: 1150px) {
      left: 84%;
    }

    @media (max-width: break-points.$lg) {
      left: 89%;
      font-size: 14px;
    }

    @media (max-width: break-points.$md) {
      left: 86%;
    }

    @media (max-width: break-points.$sm) {
      font-size: 12px;
    }

    @media (max-width: 490px) {
      left: 84%;
    }

    @media (max-width: 426px) {
      left: 82%;
    }

    @media (max-width: 377px) {
      left: 79%;
    }

    @media (max-width: 320px) {
      left: 76%;
    }
  }

  &.imageGenerationTooltip{
    margin-left: 0px;
    left: 94%;
  }

  &.promptDark::after {
    content: '';
    position: absolute;
    bottom: -15px;
    left: 28px;
    width: 0;
    height: 0;
    border: 15px solid transparent;
    border-top-color: #252526;
    border-bottom: 0;
    margin-left: -15px;
  }

 &.regenrate{
    position: absolute;
    font-size: 12px;
    line-height: initial;
    padding: 8px; 
    border-radius: 5px;
    max-width: 300px;
    z-index: z-indices.$tooltip;
    background: #ffffff;
    color: colors.$black;
    text-align: justify;
    word-break: break-word;
    box-shadow: 0px 1px 4px 0px #0C0C0D0D, 0px 1px 4px 0px #0C0C0D1A;
    border: 1px solid var(--Border-Default-Default, #D9D9D9);
  }
  &.regenrate::before{
    content: "";
    position: absolute;
    top: 100%; 
    left: 50%;
    transform: translateX(-50%);
    border-width: 7.5px;
    border-style: solid;
    border-color: #D9D9D9 transparent transparent transparent;
  }

  &.regenrate::after {
    content: "";
    position: absolute;
    top: 100%; 
    left: 50%;
    transform: translateX(-50%);
    border-width: 6px;
    border-style: solid ;
    border-color: #ffffff transparent transparent transparent;
  }

  &.regenrateDark{
    color: #fff;
    background-color: colors.$black;
    border: 1px solid var(--Border-Default-Default, #000);
  }
  &.regenrateDark::before{
    border-color: #000 transparent transparent transparent;
  }

  &.regenrateDark::after {
    border-color: #000 transparent transparent transparent;
  }
&.dark{
  background-color: #000;
  color: #fff;
}
}