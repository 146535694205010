@use "../../../../../../scss/colors";

.container {
  width: 100%;
  padding: 13px 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  &.light {
    border: 1px solid colors.$borderDark;
    background-color: colors.$white;
    color: colors.$textColorLight;
  }

  &.dark {
    background-color: colors.$stopgenerateDark;
    color: colors.$white;
  }
}

.credits {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: colors.$primaryColor;
}

.rightContainer {
  width: 63%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.buyFor {
  opacity: 0.6;
  margin-right: 8px;
}

.price {
  display: flex;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
}

.checkbox {
  width: 30px;
  height: 30px;
  border: 1.5px solid colors.$primaryColor;
  border-radius: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  &.light {
    svg {
      & path {
        fill: colors.$primaryColor;
      }
    }
  }

  &.dark {
    svg {
      & path {
        fill: colors.$white;
      }
    }
  }
}
