@use "../../../scss/colors";

.container {
  width: 100%;
  position: relative;
  border: 1px solid rgba(colors.$darkModeText, 0.1);
  border-radius: 10px;
  flex: 1;

  &.updateEmail {
    border-radius: 5px;
  }

  &.lightInputSpace {
    background: #F1F3F7;
    border: none;
  }

  &.darkInputSpace {
    background: #383E4F !important;
    border: none;
  }

  &.lightMode {
    background-color: colors.$inputLightMode;
  }

  &.promptLightMode {
    background-color: transparent;
    border: 1px solid colors.$promptborder;
  }

  &.darkMode {
    background-color: transparent;
  }

  &.grey {
    background-color: colors.$grey-50;
    border: none;
  }

  &.error {
    border-color: transparent;
    border-color: colors.$red;
  }

  &:not(.disabled, .focused):hover {
    background-color: transparent;

    &.lightMode {
      background-color: colors.$inputLightMode;
    }

    &.promptLightMode {
      background-color: transparent;
    }

    &.darkMode {
      background-color: transparent;
    }

    &.error {
      border-color: colors.$red;
    }
  }

  &.focused {
    border-color: #527AE6;

    &.lightMode {
      background-color: colors.$inputLightMode;
    }

    &.promptLightMode {
      background-color: transparent;
    }

    &.darkMode {
      background-color: transparent;
    }
  }

  &.CRFolder {
    border-radius: 5px;
    background: rgba(229, 234, 241, 0.40) !important;
    border: none;

    &.focused {
      border-color: transparent;
    }
  }

  &.CRFolderDark {
    border-radius: 5px;
    background: #3B3C7D !important;
    border: none;

    &.focused {
      border-color: transparent;
    }
  }

  &.workspace {
    border-radius: 5px;

    &.focused {
      border-color: transparent;
    }
  }
}

.input {
  outline: none;
  width: 100%;
  padding: 10px 15px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  border: none;
  background: none;
  color: colors.$grey-300;
  caret-color: colors.$grey-300;

  &.lightInputSpace {
    background: #F1F3F7;
    border: none;
    border-radius: 10px;

    &::placeholder {
      color: #A09FA2;
      opacity: 1;
    }
  }

  &.darkInputSpace {
    background: #383E4F !important;
    border: none;
    border-radius: 10px;

    &::placeholder {
      color: #737377;
      opacity: 1;

    }
  }

  &.updateEmail {
    font-size: 16px;
    padding: 10px;
    font-weight: 400;

    @media (max-width: 576px) {
      font-size: 12px;
    }
  }

  &:not(.disabled).password {
    width: calc(100% - 22px);
  }

  &::placeholder {
    color: colors.$grey-300;
  }

  &.disabled {
    pointer-events: none;
    color: colors.$grey-400;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    -webkit-text-fill-color: colors.$grey-300;
    transition: background-color 5000s ease-in-out 0s;
    background-color: transparent;
  }

  &.promptLightMode {
    &::placeholder {
      color: colors.$textColorLightMode !important;
      opacity: 0.6;
    }
  }

  &.updateEmailLight {
    background-color: #fcfcfc;
    border: 1px solid #efefef;

    &::placeholder {
      color: #2A2831;
    }
  }

  &.UpdateNameLight {
    background-color: #f8f8f8;
    border: none;

    &::placeholder {
      color: #2A2831;
    }
  }

  &.CRFolderPlac {
    color: rgba(255, 255, 255) !important;
    font-family: Raleway;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    padding: 10px 30px 10px 15px;
    /* 171.429% */

    &::placeholder {
      color: rgba(255, 255, 255, 0.60);
      opacity: 1;
    }
  }

  &.workspacePlac {
    border-radius: 4px;
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    padding: 9px 12px;
    height: 33px;

    &::placeholder {
      font-size: 12px;
      font-weight: 400;
      line-height: 15px;

      @media (max-width:576px) {
        font-size: 11px;
      }
    }

    @media (max-width:576px) {
      font-size: 11px;
    }
  }
  .workspacePlacLight {
    background-color: #fff;
    border: 0.5px solid #94A3B8;
    color: #525252;

    &::placeholder {
      color: #525252;
    }
  }

  .workspacePlacDark {
    background-color: transparent;
    border: 0.5px solid rgba(255, 255, 255, 0.1) !important;
    color: #fff;

    &::placeholder {
      color: #fff;
      opacity: 0.7;
    }
  }
}

.lightMode {
  color: colors.$textColorLight;
  caret-color: colors.$textColorLight;
  opacity: 1;

  &:focus {
    color: colors.$textColorLight;
  }

  &::placeholder {
    color: colors.$textColorLight;
    opacity: 0.7;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    -webkit-text-fill-color: colors.$textColorLight;
    transition: background-color 5000s ease-in-out 0s;
    background-color: transparent;
  }
}

.darkMode {
  color: colors.$white;
  caret-color: colors.$white;
  opacity: 1;

  &:focus {
    color: colors.$white;
  }

  &::placeholder {
    color: colors.$white;
    opacity: 0.7;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    -webkit-text-fill-color: colors.$white;
    transition: background-color 5000s ease-in-out 0s;
    background-color: transparent;
  }
}

.UpdateName {
  background-color: #212121;
  border: none;
}

.UpdateNameLight {
  background-color: #f8f8f8;
  border: none;
}

.visibilityButton {
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  height: 100%;
  width: 30px;
  align-items: center;
  justify-content: center;
  border: none;
}

.CRFolderPlac {
  color: rgba(255, 255, 255, 0.60);
  font-family: Raleway;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  caret-color: #fff;

  &::placeholder {
    color: rgba(255, 255, 255, 0.60);
    opacity: 1;
  }

  &:-webkit-autofill {
    -webkit-text-fill-color: #fff !important;
    -webkit-box-shadow: inherit !important;
    color-scheme: dark !important;
  }
}
.workspacePlac {
  border-radius: 4px;
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  padding: 9px 12px;
  height: 33px;

  &::placeholder {
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;

    @media (max-width:576px) {
      font-size: 11px;
    }
  }

  @media (max-width:576px) {
    font-size: 11px;
  }
}
.workspacePlacLight {
  background-color: #fff;
  border: 0.5px solid #94A3B8;
  color: #525252;

  &::placeholder {
    color: #525252;
  }
}

.workspacePlacDark {
  background-color: transparent;
  border: 0.5px solid rgba(255, 255, 255, 0.1) !important;
  color: #fff;

  &::placeholder {
    color: #fff;
    opacity: 0.7;
  }
}