@use "../../scss/variables";

.modalBody {
  padding: variables.$spacing-lg variables.$spacing-xl;
  overflow: auto;
  max-width: 100%;

  &.paddingNone {
    padding: 0;
  }
}
