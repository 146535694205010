@use "../../../../../../scss/colors";

.button {
  border: none;
  padding: none;
  background-color: transparent;

  &.light {
    svg {
      & path {
        stroke: colors.$textColorLight;
      }
    }
  }

  &.dark {
    svg {
      & path {
        stroke: colors.$white;
      }
    }
  }
}

.item {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: colors.$darkModeText;
  opacity: 0.7;
  width: 100%;
  cursor: pointer;

  &.light {
    color: colors.$textColorLight;
  }

  &.dark {
    color: colors.$white;
  }
}

.lineSeparator {
  width: 100%;
  opacity: 0.1;

  &.light {
    border-bottom: 1px solid colors.$textColorLight;
  }

  &.dark {
    border-bottom: 1px solid colors.$white;
  }
}
