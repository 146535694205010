.modalBody {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.headline {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  white-space: pre-wrap;
  text-align: center;
}

.successText {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  opacity: 0.7;
}
