@use "../../../../scss/colors";
@use "../../../../scss/break-points";

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;

  .message {
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px;
    white-space: normal; /* Allows wrapping of words */
    overflow-wrap: break-word; /* Ensures long words are wrapped */
    word-break: break-word; /* Compatibility for older browsers */

    &.AdminError{
      font-size: 19px;
      font-weight: 400;
      opacity: 1;
    }

    &.light {
      color: colors.$textColorLight;
    }

    &.dark {
      color: colors.$white;
    }

    @media (max-width: break-points.$sm) {
      font-size: 18px;
    }
  }

}


.uploadFail{
  width: 100%;
  height: 100%;
  padding: 25px 90px 50px 90px;
  border-radius: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: break-points.$sm) {
    padding: 10px 20px 30px 20px;
  }
}

.message {
text-align: center;
font-size: 28px;
font-style: normal;
font-weight: 700;
line-height: 30px;
word-break: break-all;
opacity: 90%;
color: colors.$docUploadFail;

@media (max-width: break-points.$sm) {
  font-size: 16px;
}
}
.validationText {
text-align: center;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 24px;
word-break: break-all;
color: colors.$darkModeText;
margin-top: 5px;
word-wrap: break-word;
word-break: normal;

&.light{
  color: colors.$textColorLightMode;
}


@media (max-width: break-points.$sm) {
  font-size: 11px;
  margin-top: 0;
}
}