@use "../../../../../../scss/break-points";
@use "../../../../../../scss/colors";

.container {
  min-width: 100%;
  display: flex;
  flex-direction: column;

  .header {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 26px 26px 0 0;
    padding: 12px 20px;
    box-sizing: border-box;
    min-height: 46px;
    justify-content: space-between;

    @media (max-width: break-points.$sm) {
      min-height: 40px;
    }

    .language {
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 28px;

      @media (max-width: break-points.$sm) {
        font-size: 14px;
        line-height: 24px;
      }
    }
  }

  .codeContainer {
    width: 100%;
  }

  .preTag {
    font-size: 16px;
    line-height: 24px;
    padding: 20px;
    border-radius: 0 0 26px 26px;

    @media (max-width: break-points.$sm) {
      font-size: 14px;
      line-height: 24px;
    }
  }         

}

.containerDark {
  border: 1px solid #1F3248;
  border-radius: 26px;

  .header {
    background: #151515;
    border-bottom: 1px solid #1F3248;

    .language {
      color: colors.$darkModeText;
      color: #527AE6;

    }
  }

  .preTag {
    color: colors.$darkModeText;
    background: #1E1E1E;
  }


}

.containerLight {
  border: 1px solid #527AE6;
  border-radius: 26px;

  .header {
    background: #EDF1FE;
    border-bottom: 1px solid #527AE6;

    .language {
      color: #527AE6;
    }
  }

  .preTag {
    background-color: #fff;
  }

}