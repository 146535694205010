@use "../../../../scss/colors";
@use "../../../../scss/break-points";
.checkboxContainer {
    display: inline-block;
    position: relative;
    cursor: pointer;
    padding: 0px 20px;
  }
  
  .checkboxContainer input {
    display: none;
  }
  
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    border: 2px solid colors.$checkBorder;
    border-radius: 50%;
  }
  .checkboxContainer input:checked ~ .checkmark {
    background-color: colors.$primaryColor; 
    border-color:  colors.$primaryColor;
  }
  
  .checkboxContainer input:checked ~ .checkmark:after {
    content: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="11" viewBox="0 0 18 13" fill="none"> <path d="M6.96994 12.5304L0.969937 6.53039C0.676688 6.23714 0.676688 5.76239 0.969937 5.46989L2.03044 4.40939C2.32369 4.11614 2.79844 4.11614 3.09094 4.40939L7.50019 8.81864L15.6594 0.659391C15.9527 0.366141 16.4274 0.366141 16.7199 0.659391L17.7804 1.71989C18.0737 2.01314 18.0737 2.48789 17.7804 2.78039L8.03044 12.5304C7.73794 12.8236 7.26244 12.8236 6.96994 12.5304Z" fill="white"/></svg>');
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .sharedocCntainer{
    margin-right: 4.5%;
    @media (max-width:break-points.$lg) {
      margin-right: 6.5%;
     }
    @media (max-width:break-points.$sm) {
     margin-right: 10.5%;
    }
  }