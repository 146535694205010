@use '../../../../../../../scss/colors';
@use '../../../../../../../scss/break-points';

.Container {
    z-index: 999;
    border-radius: 5px;
    background: linear-gradient(90deg, #6A87E5 1.64%, #7C71F6 100%);
    width: 94%;
    right: 3%;

    /* sidebar */
    box-shadow: 0px 68px 19px 0px rgba(0, 0, 0, 0.00), 0px 43px 17px 0px rgba(0, 0, 0, 0.01), 0px 24px 15px 0px rgba(0, 0, 0, 0.05), 0px 11px 11px 0px rgba(0, 0, 0, 0.09), 0px 3px 6px 0px rgba(0, 0, 0, 0.10);

    &.dropDownAdjustment {
        width: 96%;
        right: 2%;
    }

    &.dark {
        border-radius: 5px;
        background: linear-gradient(132deg, #382E5D -10.12%, #303E80 65.08%, #383061 135.89%, #313E80 135.9%);

        /* sidebar */
        box-shadow: 0px 68px 19px 0px rgba(0, 0, 0, 0.00), 0px 43px 17px 0px rgba(0, 0, 0, 0.01), 0px 24px 15px 0px rgba(0, 0, 0, 0.05), 0px 11px 11px 0px rgba(0, 0, 0, 0.09), 0px 3px 6px 0px rgba(0, 0, 0, 0.10);
    }

    .innerContainer {
        margin: 5px;
        border-radius: 5px;
        background: #FFFFFF26;
        box-shadow: 0px 620px 174px 0px rgba(46, 55, 127, 0.00), 0px 397px 159px 0px rgba(46, 55, 127, 0.01), 0px 223px 134px 0px rgba(46, 55, 127, 0.05), 0px 99px 99px 0px rgba(46, 55, 127, 0.09), 0px 25px 55px 0px rgba(46, 55, 127, 0.10);

        &.dark {
            background: #1E193C33;
        }

    }

    .textContainer {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-left: 10px;

        &.light {
            color: colors.$textColorLightMode;
        }

        &.dark {
            color: colors.$darkModeText;
        }

        @media (max-width: break-points.$sm) {
            gap: 2px;
        }
    }

    .title {
        font-style: normal;
        font-weight: 600;
        font-size: 10px;
        line-height: 20px;
        color: #fff;
    }

    .feature {
        font-style: normal;
        font-weight: 400;
        font-size: 8px;
        // line-height: 24px;
        color: #fff;
    }

    .icon {
        width: 17px;
        height: 17px;

        svg {
            & path {
                stroke: #fff;
            }
        }
    }

    .selectedButton {
        color: #5B71F5;
        font-family: Raleway;
        font-size: 8px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;

        /* 300% */
        &.light {
            color: colors.$textColorLight;
            background-color: colors.$darkModeText;
            border: 1px solid transparent;
        }

        &.dark {
            color: colors.$white;
            background-color: rgba(255, 255, 255, 0.03);
            border: 1px solid transparent;
        }

        &:hover {
            &.light {
                color: colors.$textColorLight;
            }

            &.dark {
                color: colors.$white;
            }
        }
    }

    .svg {
        border-top: 1px solid rgba(255, 255, 255, 0.10);
        margin: 5px 10px;

        &.dark {
            border-top: 1px solid rgba(30, 25, 60, 0.15);
        }
    }

    .isbackdrop {
        opacity: 0.4;
        pointer-events: none; // Disables all interactions
    }
}