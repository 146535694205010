@use "../../../../../../../scss/break-points";
@use "../../../../../../../scss/colors";

.container {
  padding: 20px 50px 40px 50px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: break-points.$sm) {
    padding: 15px 20px;
  }

  .message {
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px;
    margin-bottom: 25px;

    @media (max-width: break-points.$sm) {
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 15px;
    }
  }

  .buttonsContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;

    @media (max-width: break-points.$sm) {
      gap: 8px;
    }

    .cancelBtnText {
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      padding: 0 24px;

      &.light {
        color: colors.$textColorLight;
      }

      &.dark {
        color: colors.$white;
      }

      @media (max-width: break-points.$sm) {
        font-size: 14px;
        padding: 0 15px;
      }
    }

    .buttonText {
      color: colors.$white;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      padding: 0 24px;

      @media (max-width: break-points.$sm) {
        font-size: 14px;
        padding: 0 15px;
      }
    }
  }
}
