@use "../../scss/colors";

.container {
  @media screen and (min-width: 769px) {
    min-height: calc(100vh - 154px);
    display: flex;
    flex-direction: column;
  }
}

.pageTitle {
  line-height: 64px;
  font-size: 40px;
  font-weight: 700;
  text-align: center;
  padding-top: 64px;

  &.light {
    color: colors.$textColorLight;
  }
  &.dark {
    color: colors.$white;
  }

  @media screen and (min-width: 769px) {
    padding-top: 128px;
  }
}

.tabs {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin: 16px 0;
}

.tab {
  border-radius: 10px;
  padding: 10px 20px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  opacity: 0.8;
  cursor: pointer;
}

.tabLight {
  color: colors.$textColorLight;

  &.active {
    opacity: 1;
    background-color: colors.$headerBackground;
  }
}

.tabDark {
  color: colors.$white;

  &.active {
    opacity: 1;
    background-color: rgba(255, 255, 255, 0.02);
  }
}

.tabContent {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin: 80px 0;
  padding: 0 15px;

  @media screen and (min-width: 769px) {
    flex-direction: row;
    flex: 1;
    justify-content: space-around;
  }
}

.content {
  display: flex;
  flex-direction: column;
  flex: 1;

  @media screen and (min-width: 769px) {
    margin-right: 80px;
    width: 45%;
    flex: none;
  }
}

.title {
  margin: 0;
  font-weight: 700;
  font-size: 28px;
  line-height: 54px;

  &.light {
    color: colors.$textColorLight;
  }
  &.dark {
    color: colors.$white;
  }

  @media screen and (min-width: 769px) {
    font-size: 40px;
  }
}

.text {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  opacity: 0.7;
  margin-top: 16px;

  &.light {
    color: colors.$textColorLight;
  }
  &.dark {
    color: colors.$white;
  }
}

.imgbox {
  border-radius: 8px;
  padding: 0 40px;
  flex: 1;
  margin-top: 40px;
  max-width: 600px;

  @media screen and (min-width: 769px) {
    margin-top: 0;
  }

  img {
    width: 100%;
    height: 100%;
  }
}
