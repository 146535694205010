@use "../../../../../../scss/colors";

.modalBody {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.headline {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  white-space: pre-wrap;
  text-align: center;
}

.message {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  opacity: 0.7;
  text-align: center;
}

.footer {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 12px;
}

.buttonText {
  &.light {
    color: colors.$textColorLight;
  }

  &.dark {
    color: colors.$white;
  }
}
