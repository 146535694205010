$blue-100: #cbc8ff;
$blue-200: #a6a4ff;
$blue-300: #7f7eff;
$blue-400: #4f46e5;
$blue-500: #433bfb;
$blue-600: #3f30ee;
$blue-700: #3622e1;
$blue-800: #2e0ad7;
$blue-900: #2800bd;
$blue: $blue-400;
$blue-transparent: rgba($blue, 0.1);

$dark-blue-50: #e6e7ef;
$dark-blue-100: #c0c3d9;
$dark-blue-150:#F1F1F6;
$dark-blue-200: #989dbf;
$dark-blue-300: #989dbf;
$dark-blue-400: #565c94;
$dark-blue-500: #3b4283;
$dark-blue-600: #353b7b;
$dark-blue-700: #2d3270;
$dark-blue-800: #262963;
$dark-blue-900: #1a194d;
$dark-blue: $dark-blue-900;

$grey-30: #fcfcfd;
$grey-40: #f8f8fe;
$grey-50: #f8f8fa;
$grey-100: #e8e8ed;
$grey-200: #d9d9e0;
$grey-300: #afafc1;
$grey-400: #8b8ba0;
$grey-500: #717189;
$grey-600: #595971;
$grey-700: #494961;
$grey-800: #35354a;
$grey-900: #252536;
$grey: $grey-300;

$orange-50: #fae9e8;
$orange-100: #fecbbf;
$orange-200: #fea996;
$orange-300: #fe866c;
$orange-400: #ff6a4d;
$orange-500: #ff4f31;
$orange-600: #f4492d;
$orange-700: #e64228;
$orange-800: #d83c24;
$orange-900: #bf2f1b;
$orange: $orange-400;

$green-50: #dcf6f8;
$green-100: #a7e9ec;
$green-200: #67dae1;
$green-300: #00cbd6;
$green-400: #00c0cd;
$green-500: #00b5c7;
$green-600: #00a5b5;
$green-700: #00909b;
$green-800: #007c84;
$green-900: #005959;
$green: #068c24;

$red-50: #ffe4e8;
$red-100: #ffbac6;
$red-200: #ff8da1;
$red-300: #ff5e7b;
$red-400: #fb395f;
$red-500: #f51a46;
$red-600: #e51145;
$red-700: #d00543;
$red-800: #bc0042;
$red-900: #99003f;
$red: #ff5454;

$beige-50: #fef9f4;
$beige-100: #ffebd7;
$beige: $beige-50;

$yellow-50: #fdf8e1;
$yellow-100: #fbecb3;
$yellow-200: #f9e081;
$yellow-300: #f8d54e;
$yellow-400: #f7ca26;
$yellow-500: #f6c000;
$yellow-600: #f6b300;
$yellow-700: #f7a000;
$yellow-800: #f79000;
$yellow-900: #f77100;
$yellow: $yellow-500;

$leftpanelDark: #17171b;
$themeActiveDark: #141517;

$stopgenerateLight: #efedf3;
$stopgenerateDark: #101113;
$benefitTitleDark: #252434;
$headerBackground: #f9f9f9;

$cancelShareBtn: #9F75FF;
$shareChatLight: #F0F4F9;
$lightRedColor: #ff8870;
$borderBlackColor: #202123;
$tableThColor: #e5e7eb;
$backgroundColor: #eff0f5;
$textColorLightMode: #2A2831;

$darkModeText:#fff;
$chatFooterBorderTop:  #CDD0D3;
$promptborder: #959595;
$black: #000;
$black300: #27272a;
$primaryColor: #7c4dff;
$primaryColor20: rgba(103, 87, 255, 0.2);
$mainViewColor: #E9EEF4;
$mainViewDarkMode: #171718;

$searchDarkMode: #212122;
$borderDark: #202123;
$landingPriceBorderDark: rgba(214, 221, 228, 0.6);
$borderLight: #c2c8cd;
$textColorLight:#2a2831;
$creditColor: #71717A;
$checkBorder: #888;
$codeBlockBackground: #2f2d34;
$codeBlock: #1e1d20;
$codeBlockLight: #e8e8eb;
$codeBlockLightMode: #f7f7f9;
$darkModeBackground: #131314;
$heroText: #6f53ff;
$heroText2: #c757ff;

$lightModelineargradient1: #9D7FFC;      
$lightModelineargradient2:  #5F8BE3;
$lightModelineargradient3: #737DE9;
$lightModelineargradient4: #6B8DDC;
$darkModeLineargradient1: #342C55;
$darkModeLineargradient2: #35416E;
$darkModeLineargradient3: #373866;
$darkModeLineargradient4: #2F395B;

$colorLineargrandient: #8c49f7;
$colorLineargrandient2: #6c53ff;
$userNameBackground: #d9d9d9;
$sidebarLogoText: #7850DD;
$sidebarLogoText2: #4693E8;
$tbodybackground: #1e1f20;
$tbodyDarkMode: #303131;
$chatItembackground: #343541;
$modalDarkModeBackground: #252526;

$settingSideBarLightMode: #F0F4F9;
$settingSideBarBorderTop: #434445;
$searchfieldActiveLight: #EFEAFF;
$searchfieldActivedark: #231D34;
$docUploadFail:#FF0C00;

$primaryColor12: rgba(103, 87, 255, 12%);
$shadowColor: rgba(0, 0, 0, 25%);
$cardShadowColor: rgba(26, 25, 77, 12%);
$transparentColor: rgba(255, 255, 255, 0%);
$borderWhiteColor: rgba(255, 255, 255, 0.2);
$white: rgba(#fff, 0.8);

$lightThemeColor: rgba( 255, 255, 255, 1);
$promptplaceholderLight: rgba(#2a2831, 0.6);
$docBorder: rgba(67, 68, 69, 0.40);
$modalBackDrop: rgba(21, 21, 21, 0.50);
$modalBorder: rgba(42, 40, 49, 0.10);

$sideBarBackground: rgba(255, 255, 255, 0.20);
$answerActionIcon: rgba(128, 68, 219, 1);
$searchLightMode: rgba(31, 31, 31, 0.60);
$searchBackgroundLight: #fdfdfd;
$searchfieldActive: rgba(124, 77, 255, 0.12);

$newChatbtn: rgba(229, 234, 241, 0.40);
$chatMessage: rgba(180, 180, 180, 0.9);

$darkModeSetting: rgba(255, 255, 255, 0.06);
$themeSetting: rgba(229, 234, 241, 0.50);
$chatSettingUsageTag: rgba(195, 173, 255, 1);
$inputLightMode: rgba(42, 40, 49, 0.04);
$scrollDarkMode: rgba(38, 38, 38, 0.8);
$promptborder: rgba(31, 31, 31, 0.40);
$burgerIconColor: #2D3648;
