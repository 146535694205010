@use "../../scss/colors";

.title {
  padding: 48px 0;
  text-align: center;

  &.light {
    color: colors.$textColorLight;
  }

  &.dark {
    color: colors.$white;
  }
}

.content {
  line-height: 24px;
  white-space: pre-wrap;
  font-size: 16px;
  padding: 0 20px 100px 20px;

  h4 {
    font-size: 18px;
  }
  
  &.light {
    color: colors.$textColorLight;
  }

  &.dark {
    color: colors.$white;
  }
}
