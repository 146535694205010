@use "../../../scss/variables";
@use "../../../scss/break-points";
@use "../../../scss/colors";


.mainViewontainer {
  &.light {
    background-color: rgb(255, 255, 255);
  }

  &.dark {
    background-color: #181819;
  }
}

.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  position: relative;

  &.lightSettings {
    background: #F8FBFF;
  }

  &.lightChat {
    background-color: colors.$lightThemeColor;
  }

  &.dark {
    background-color: #181819;
  }

  &.chatContainer {
    padding: 0 variables.$pagePadding 0 0;

    &.isSidebarOpen {

      @media (min-width: 775px) {
        padding: 0 variables.$pagePadding 0 0;
      }

      padding: 0 variables.$pagePadding 0 variables.$pagePadding;

      @media (max-width:break-points.$sm) {
        padding: 0;
      }
    }
  }

  &.settingContainer {
    padding: 0 variables.$pagePadding variables.$pagePadding 0;

    &.isSidebarOpen {
      padding: 0 variables.$pagePadding variables.$pagePadding variables.$pagePadding;

      @media (min-width: 775px) {
        padding: 0 variables.$pagePadding variables.$pagePadding 0;
      }

      @media (max-width:break-points.$sm) {
        padding: 0;
      }
    }
  }

  &.isShareChat {
    background-color: colors.$shareChatLight;
  }

  &.dragbackdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: colors.$modalBackDrop;
    z-index: 99999;
  }
}

.content {
  width: 100%;
  margin-left: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow-y: auto;
  padding: 16px 0;
  transition: width variables.$sb-transition ease,
    margin-left variables.$sb-transition ease;
  border-radius: 20px;

  &.light {
    background-color: colors.$white;
  }

  &.dark {
    background-color: #181819;
  }

  &.isSidebarOpen {
    transition: width variables.$sb-transition ease,
      margin-left variables.$sb-transition ease;

    @media (max-width: break-points.$md) {
      width: 100%;
      margin-left: 0;
      transition: width none, margin-left none;

    }
  }

  &.isShareChat {
    margin-left: 40px;
    width: 100%;
    transition: width variables.$sb-transition ease;
  }

  @media (max-width: break-points.$md) {
    width: 100%;
    margin-left: 0;
    padding: 20px;
  }

  &.shareChat {
    background-color: colors.$shareChatLight;
  }

  &.dragBackdropOpen {
    position: relative;
    z-index: 10002;
    /* Adjust the z-index to be above the backdrop */
  }

  &.workSpace {
    border-radius: 0px;
    padding: 0px;
  }

  &.workSpacelight {
    background-color: #f8fafc;
  }

  .workSpacedark {
    background-color: #1a1a1a;
  }

  &.disAbleContent {
    background-color: rgba(21, 21, 21, 0.1) !important;
    pointer-events: none;
    opacity: 0.4;

  }

}

.chatContent {
  height: calc(100% - (variables.$navbar + variables.$chatFooter + variables.$pagePadding));
  margin-top: variables.$navbar;
  flex-direction: column;
  padding: 0;
  border-radius: 20px 20px 0 0;

  @media (max-width: break-points.$md) {
    height: calc(100% - (variables.$md-navbar + variables.$chatFooter + variables.$pagePadding));
    margin-top: variables.$md-navbar;
  }

  @media (max-width: break-points.$sm) {
    padding: 0 0 15px 0;
    height: calc(100% - (variables.$md-navbar + variables.$chatFooterSM + variables.$pagePadding ));
  }
}

.IGChatContent {
  height: calc(100% - (variables.$navbar + variables.$IGChatFooter + variables.$pagePadding));

  @media (max-width: break-points.$md) {
    height: calc(100% - (variables.$md-navbar + variables.$IGChatFooter + variables.$pagePadding ));
    margin-top: variables.$md-navbar;
  }

  @media (max-width: break-points.$sm) {
    height: calc(100% - (variables.$md-navbar + variables.$IGChatFooterSM + variables.$pagePadding ));
  }

  @media (max-width: 335px) {
    height: calc(100% - (variables.$navbar + variables.$IGChatFooter + variables.$pagePadding));
  }
}

.IGChatContentsidebarOpen {
  @media (max-width: 1120px) {
    height: calc(100% - (variables.$navbar + variables.$IGChatFooter + variables.$pagePadding + 80px));
  }
}

.IChatContent {
  height: calc(100% - (variables.$navbar + variables.$IChatFooter + variables.$pagePadding));
}

.IUplodingFile {
  height: calc(100% - (270px));
}

.settingsContent {
  height: calc(100% - variables.$navbar);
  margin-top: variables.$navbar;


  @media (max-width: break-points.$md) {
    height: calc(100% - (variables.$md-navbar));
    margin-top: variables.$md-navbar;
  }
}

.resizeNavbarContent {
  margin-top: 0px;

}

.resizeNavbar {
  margin-top: 0px;

  @media (max-width: break-points.$lg) {
    margin-top: variables.$md-navbar;
  }
}

.settingsContentlight {
  background: colors.$darkModeText;
  border-radius: 0;
}

.settingsContentdark {
  border-radius: 0;
}

.newChatContent {
  height: 100%;
}




.Sidebar_container {
  display: flex;
  /* Ensures it uses flexbox */
  flex-direction: column;
  /* Aligns content vertically */
  height: 100%;
  /* Full height */
  background-color: #f0f0f0;
  /* Sidebar background */
}

/* CustomSplitPane.css */
.customResizer {
  will-change: width, margin-right;
  transition: width 0.2s ease-in-out;
  width: 5px;
  height: 100%;
  cursor: col-resize;
  background: #ccc;
  z-index: 999999;
  opacity: 0;
}

.customResizerHover {
  background: #ccc;
  opacity: 0.5;
}

.customResizer:hover {
  background: #ccc;
  opacity: 0.5;
}