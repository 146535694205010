@use "../../../../../../scss/colors";
@use "../../../../../../scss/break-points";

.container {
  width: 100%;
  height: 100%;

  .header {
    width: 100%;
    height: 50px;
    border-radius: 20px 20px 0 0;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 45px;

    &.light {
      color: colors.$textColorLight;
      @media (max-width: break-points.$sm) {
      }
    }

    &.dark {
      color: colors.$white;
    }

    .backButton {
      position: absolute;
      left: 22px;
      background-color: transparent;
      padding: 0;
      margin: 0;

      &.light {
        svg {
          & path {
            fill: colors.$textColorLightMode;
          }
        }
      }

      &.dark {
        svg {
          & path {
            fill: colors.$darkModeText;
          }
        }
      }
    }

    .headline {
      text-align: center;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 30px;
      word-break: break-all;

      &.light {
        color: colors.$textColorLightMode
      }

      &.dark {
        color: colors.$darkModeText;
      }

      @media (max-width: break-points.$sm) {
        font-size: 14px;
      }
    }
  }

  .body {
    width: 100%;
    height: calc(100% - (50px + 40px));
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 45px;
    box-sizing: border-box;

    svg {
      & path {
        fill: colors.$primaryColor;
      }
    }

    &.light {
      color: colors.$textColorLightMode;
      @media (max-width: break-points.$sm) {
        background-color: colors.$dark-blue-150;
      }
    }

    &.dark {
      color: colors.$darkModeText;
      @media (max-width: break-points.$sm) {
        background-color: colors.$modalDarkModeBackground;
      }
    }

    &.isForm {
      height: calc(100% - (50px + 70px));
    }

    &.isLoading {
      min-height: 232px;
      justify-content: center;
    }

    .uploadingContainer {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;
      padding: 10px;

      .message {
        text-align: center;
        font-size: 19px;
        font-style: normal;
        font-weight: 700;
        line-height: 30px;
        word-break: break-all;

        @media (max-width: break-points.$sm) {
          font-size: 16px;
        }
        
      }

      .docName {
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        word-break: break-all;
        color: colors.$creditColor;

        @media (max-width: break-points.$sm) {
          font-size: 12px;
        }
      }

      .percentageContainer {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 5px;

        .barContainer {
          width: 100%;
          height: 5px;
          border-radius: 5px;
          position: relative;

          &.light {
            background-color:  rgba(124, 77, 255, 0.1);
          }

          &.dark {
            background-color: colors.$white;
          }

          .bar {
            position: absolute;
            top: 0;
            left: 0;
            height: 5px;
            border-radius: 5px;
            background-color: colors.$primaryColor;
          }
        }

        .percentage {
          text-align: center;
          font-size: 19px;
          font-style: normal;
          font-weight: 500;
          line-height: 30px;
          color: colors.$creditColor;

          @media (max-width: break-points.$sm) {
            font-size: 16px;
          }
        }
      }

    }

    .dropzone {
      width: 100%;
      height: 100%;
      padding: 10px;
      border-radius: 20px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      border: 3px dashed colors.$primaryColor;
 svg{
  height: 95px;
  width: 200px;
 }
      &.light {
        background-color: rgba(255, 255, 255, 0.3);

        &.isDragOver {
          box-shadow: 0px 0px 0px 0px rgba(colors.$textColorLight, 0.05),
            1px 2px 5px 0px rgba(colors.$textColorLight, 0.05),
            6px 7px 9px 0px rgba(colors.$textColorLight, 0.04),
            13px 16px 12px 0px rgba(colors.$textColorLight, 0.03),
            23px 28px 15px 0px rgba(colors.$textColorLight, 0.01),
            37px 43px 16px 0px rgba(colors.$textColorLight, 0);
        }
      }

      &.dark {

        &.isDragOver {
          box-shadow: 0px 0px 0px 0px rgba(colors.$white, 0.05),
            1px 2px 5px 0px rgba(colors.$white, 0.05),
            6px 7px 9px 0px rgba(colors.$white, 0.04),
            13px 16px 12px 0px rgba(colors.$white, 0.03),
            23px 28px 15px 0px rgba(colors.$white, 0.01),
            37px 43px 16px 0px rgba(colors.$white, 0);
        }
      }

      @media (max-width: break-points.$sm) {
        border-radius: 10px;
      }

      .uploadIcon {
        width: 35px;
        height: 47px;

        @media (max-width: break-points.$sm) {
          width: 26px;
          height: 34px;
        }
      }

      .message {
        text-align: center;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 30px;
        word-break: break-all;
        margin-top: 20px;

        @media (max-width: break-points.$sm) {
          margin-top: 0;
          font-size: 14px;
        }
      }

      .validationText {
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        word-break: break-all;

        @media (max-width: break-points.$sm) {
          font-size: 16px;
          margin-top: 8px;
        }
      }

      .clickHereText {
        color: colors.$primaryColor;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin-top: 50px;

        @media (max-width: break-points.$sm) {
          font-size: 13px;
          margin-top: 15px;
        }
      }
    }

    .inputFile {
      display: none;
    }
  }

  .footer {
    width: 100%;
    height: 40px;
    border-radius: 0 0 20px 20px;
    position: relative;
    padding: 0 45px;
    display: flex;

    &.light {
      color: colors.$textColorLight;
      @media (max-width: break-points.$sm) {
        background-color: colors.$dark-blue-150;
      }
    }

    &.dark {
      color: colors.$white;
      @media (max-width: break-points.$sm) {
        background-color: colors.$modalDarkModeBackground;
      }
    }

    &.isForm {
      height: 70px;
    }

    .text {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;

      span {
        color: colors.$primaryColor;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 26px;
        cursor: pointer;

        @media (max-width: break-points.$sm) {
          font-size: 12px;
        }
      }
    }

    .form {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;

      .input {
        width: 100%;
        border-radius: 10px 0px 0px 10px;
        border: 1px solid colors.$primaryColor;
        background-color: rgba(colors.$darkModeText, 0.03);
        backdrop-filter: blur(25px);
        padding: 9px 13px;
        box-sizing: border-box;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;

        &.light {
          color: colors.$textColorLight;
        }

        &.dark {
          color: colors.$white;
        }

        @media (max-width: break-points.$sm) {
          font-size: 14px;
        }
      }

      button {
        padding: 10px 0;
        min-width: 122px;
        box-sizing: border-box;
        border-radius: 0px 10px 10px 0px;
        background: colors.$primaryColor;
        color: colors.$white;
        font-size: 17px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;

        @media (max-width: break-points.$sm) {
          min-width: 75px;
          font-size: 14px;
        }

        &.isLoading {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          padding: 14.5px 0;
        }
      }
    }
  }
}


.dropzone {
  width: 100%;
  height: 100%;
  padding: 10px;
  border-radius: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  svg{
    @media (max-width: break-points.$sm) {
    height: 105px;
    width: 260px;
    }
   }

  &.light {

    &.isDragOver {
      box-shadow: 0px 0px 0px 0px rgba(colors.$textColorLight, 0.05),
        1px 2px 5px 0px rgba(colors.$textColorLight, 0.05),
        6px 7px 9px 0px rgba(colors.$textColorLight, 0.04),
        13px 16px 12px 0px rgba(colors.$textColorLight, 0.03),
        23px 28px 15px 0px rgba(colors.$textColorLight, 0.01),
        37px 43px 16px 0px rgba(colors.$textColorLight, 0);
    }
  }

  &.dark {
    &.isDragOver {
      box-shadow: 0px 0px 0px 0px rgba(colors.$white, 0.05),
        1px 2px 5px 0px rgba(colors.$white, 0.05),
        6px 7px 9px 0px rgba(colors.$white, 0.04),
        13px 16px 12px 0px rgba(colors.$white, 0.03),
        23px 28px 15px 0px rgba(colors.$white, 0.01),
        37px 43px 16px 0px rgba(colors.$white, 0);
    }
  }

  @media (max-width: break-points.$sm) {
    border-radius: 10px;
  }

  .uploadIcon {
    width: 35px;
    height: 47px;

    @media (max-width: break-points.$sm) {
      width: 26px;
      height: 34px;
    }
  }

  .message {
    text-align: center;
    font-size: 25px;
    font-style: normal;
    font-weight: 700;
    line-height: 27px;
    word-break: break-all;
    margin-top: 20px;
    color: #5F72F3;

    @media (max-width: break-points.$sm) {
      margin-top: 0;
      font-size: 25px;
    }
  }

  .validationText {
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    word-break: break-all;
    color: colors.$lightThemeColor;
    margin-top: 16px;

    &.light{
    color: #2A2831;
    }

    &.dark{
    color: #FFF;
    }

    @media (max-width: break-points.$sm) {
      font-size: 16px;
      margin-top: 8px;
      width: 200px;
    }

  }

  .clickHereText {
    color: colors.$primaryColor;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: 50px;

    @media (max-width: break-points.$sm) {
      font-size: 13px;
      margin-top: 15px;
    }
  }
}