@use "../../../../../scss/variables";
@use "../../../../../scss/colors";

.container {
  width: 100%;
  height: inherit;
  display: flex;
  align-items: center;

  &.light {
    color: colors.$textColorLight;
  }

  &.dark {
    color: colors.$white;
  }

  .heading {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    margin-left: 37px;

    &.isSidebarOpen {
      margin-left: 0;
    }
  }
}
