@use "../../../../../scss/colors";
@use "../../../../../scss/break-points";

.header {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
}

.headerTextContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.crossBtn {
    background-color: transparent;
    cursor: pointer;
}

.title {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    display: flex;
    align-items: center;

    @media (max-width: 576px) {
        font-size: 14px;
    }
}

.description {
    color: #9D9D9D;
    font-family: Raleway;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    padding-right: 10px;
    width: 100%;

    @media (max-width: 576px) {
        font-size: 13px;
    }

    &.confirmDeActivate {
        display: flex;
        width: 100%;
        justify-content: center;
        padding: 28px 0px;
        color: #71717A;
        text-align: center;
        font-family: Raleway;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;

        @media (max-width: break-points.$sm) {
            font-size: 14px;
            padding: 18px 0px;
        }
    }
}

.buttonText {
    &.light {
        color: #A09FA2;
    }

    &.dark {
        color: colors.$white;
    }
}

.optionItem {
    display: flex;
    padding: 10px;
    align-items: center;
    gap: 18px;
    background: #F8F8F8;
    margin-bottom: 10px;

    &.light {
        background: #F8F8F8;
    }

    &.dark {
        background: #212121;
    }
}

.checkboxContainer {
    display: inline-block;
    position: relative;
    padding-left: 25px;
    cursor: pointer;
    user-select: none;
    // bottom: 9px;
    position: absolute;
    right: 25px;
    padding: 10px 13px;


    .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 20px;
        width: 20px;
        background-color: transparent;
        // background-color: #fff;
        border-radius: 50%;
        border: 2px solid #ccc;
    }
    
    input:checked+.checkmark {
        background-color: #fff;
        border: 2px solid #5B71F5;
    }
    
    .checkmark::after {
        content: "";
        position: absolute;
        display: none;
    }
    
    input:checked+.checkmark::after {
        display: block;
    }
    
    .checkmark::after {
        top: 1.8px;
        left: 1.3px;
        width: 13.5px;
        height: 13px;
        border-radius: 50%;
        background: #5B71F5;
        /* Inner circle color */
    }
    
}

.checkboxContainer input {
    display: none;
}

// .checkmark {
//     position: absolute;
//     top: 0;
//     left: 0;
//     height: 20px;
//     width: 20px;
//     background-color: transparent;
//     // background-color: #fff;
//     border-radius: 50%;
//     border: 2px solid #ccc;
// }

// input:checked+.checkmark {
//     background-color: #fff;
//     border: 2px solid #5B71F5;
// }

// .checkmark::after {
//     content: "";
//     position: absolute;
//     display: none;
// }

// input:checked+.checkmark::after {
//     display: block;
// }

// .checkmark::after {
//     top: 1.8px;
//     left: 1.3px;
//     width: 13px;
//     height: 13px;
//     border-radius: 50%;
//     background: #5B71F5;
//     /* Inner circle color */
// }


.checkboxDarkContainer {
    display: inline-block;
    position: relative;
    padding-left: 25px;
    cursor: pointer;
    user-select: none;
    position: absolute;
    right: 25px;
    padding: 10px 13px;

    .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 20px;
        width: 20px;
        background-color: transparent;
        border-radius: 50%;
        border: 2px solid #ccc;
    }
    
    input:checked+.checkmark {
        background-color: #212121;
        border: 2px solid #5B71F5;
    }
    
    .checkmark::after {
        content: "";
        position: absolute;
        display: none;
    }
    
    input:checked+.checkmark::after {
        display: block;
    }
    
    .checkmark::after {
        top: 1.8px;
        left: 1.3px;
        width: 13.5px;
        height: 13px;
        border-radius: 50%;
        background: #5B71F5;
        /* Inner circle color */
    }
}


.checkboxDarkContainer input {
    display: none;
}

.ConfirmDeActivate {
    text-align: center;
    font-family: Raleway;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;

    &.light{
        color: #2A2831;
    }

    &.dark{
        color: #fff;
    }

    @media (max-width: break-points.$md) {
        font-size: 26px;
    }

    @media (max-width: break-points.$sm) {
        font-size: 18px;
    }
}

.confirmBtn{
    color: #252526 !important;
}