@use "../../scss/colors";
@use "../../scss/break-points";

.label {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 32px;

  &.currentPlan{
    @media (max-width: break-points.$sm) {
    width: 31px;
height: 15px;
margin-top: 6px;
    }
  }
  &.chat{
    width: 34px;
    height: 17px;
  }
}

.input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(92.31deg, colors.$colorLineargrandient 0%, colors.$colorLineargrandient2 100%);
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 32px;
&.currentPlan{
  background: linear-gradient(93deg, #4693E8 -41.18%, #6C53FF 100%) !important;
}
  &.chat {
    background: #c2c2c2;
  }

  &::before {
    position: absolute;
    content: "";
    height: 24px;
    width: 24px;
    left: 4px;
    bottom: 4px;
    background-color: colors.$white;
    transition: 0.4s;
    border-radius: 50%;
  }

  &::after {
    position: absolute;
    content: "";
    height: 24px;
    width: 24px;
    left: 4px;
    bottom: 4px;
    background-color: colors.$white;
    transition: 0.4s;
    border-radius: 50%;
  }
&.currentPlan{
  background: linear-gradient(93deg, #4693E8 -41.18%, #6C53FF 100%) !important;
  @media (max-width: break-points.$sm) {
  &::before {
    position: absolute;
    content: "";
    height: 12px;
    width: 12px;
    left: 2px;
    bottom: 1.5px;
    background-color: colors.$white;
    transition: 0.4s;
    border-radius: 50%;
  }

  &::after {
    position: absolute;
    content: "";
    height: 12px;
    width: 12px;
    left: 2px;
    bottom: 1.5px;
    background-color: colors.$white;
    transition: 0.4s;
    border-radius: 50%;
  }
}
}
  &.chat {
    background: #c2c2c2;

    &::before {
      position: absolute;
      content: "";
      height: 14px;
      width: 14px;
      left: 2px;
      bottom: 1.5px;
      background-color: colors.$white;
      transition: 0.4s;
      border-radius: 50%;
    }

    &::after {
      position: absolute;
      content: "";
      height: 14px;
      width: 14px;
      left: 2px;
      bottom: 1.5px;
      background-color: colors.$white;
      transition: 0.4s;
      border-radius: 50%;
    }
  }


}

.iconSlider {
  background: colors.$white;

  &::before {
    background-image: url("../../../public/icons/moon.svg");
    background-position: center;
    background-repeat: no-repeat;
    box-shadow: 0px 0px 1px rgba(26, 32, 36, 0.32),
      0px 1px 2px rgba(91, 104, 113, 0.32);
  }

  &::after {
    background-image: url("../../../public/icons/sun-active.svg");
    background-position: center;
    background-repeat: no-repeat;
  }
}

.input:checked+.slider {
  background: linear-gradient(92.31deg, colors.$colorLineargrandient 0%, colors.$colorLineargrandient2 100%);
}

.iconInput:checked+.slider {
  background: colors.$white;
  border: 1px solid colors.$primaryColor;
}

.input:focus+.slider {}

.iconInput:checked+.slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.iconInput:checked+.slider::after {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}
.input:checked+.slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.input:checked+.slider::after {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}
.chatInput:checked+.slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(16px);
}

.chatInput:checked+.slider::after {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(16px);
}
.currentInput:checked+.slider:before {
  @media (max-width: break-points.$sm) {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(16px);
  }
}

.currentInput:checked+.slider::after {
  @media (max-width: break-points.$sm) {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(16px);
  }
}