@use "../../../../../scss/variables";
@use "../../../../../scss/colors";
@use "../../../../../scss/break-points";

.container {
  width: 100%;
  color: colors.$darkModeText;

  @media (max-width:break-points.$sm) {
    height: 100%;
  }

  &.light {
    color: colors.$textColorLightMode;
  }

  &.dark {
    color: colors.$darkModeText;
  }

  &.team {
    color: colors.$darkModeText;
  }

  .icon {
    display: flex;
    gap: 12px;
    padding: 10px 0px;

    @media (max-width:break-points.$sm) {
      padding: 9px 15px;
      gap: 10px;
      align-items: center;
    }

    &.light {
      svg {
        & path {
          fill: colors.$textColorLightMode;
        }
      }
    }
  }

  .h2 {
    font-size: 23px;
    font-weight: 700;

    &.settingsH2 {
      font-size: 16px;
      font-weight: 500;
    }
  }

  .memu {
    margin-top: 16px;

    @media (max-width:break-points.$md) {
      margin-top: 0px;
      height: 100%;
    }

    .footer {
      width: 100%;
      padding: 18px 0;

      &.light {
        border-top: 1px solid colors.$borderLight;
      }

      &.dark {
        border-top: 1px solid colors.$settingSideBarBorderTop;
      }
    }

    .menuItem {
      border-radius: 10px;
      display: flex;
      align-items: center;
      padding: 14px 10px;
      margin-left: -10px;
      cursor: pointer;
      gap: 12px;
      z-index: 99999999;
      transition: none !important;
          
      &.active {
        @media (max-width:break-points.$sm) {
          border-radius: 20px;
          background: rgba(229, 234, 241, 0.30);
          margin: 0px 54px 0px 27px;
          padding: 2px 10px 2px !important;
          transition: none !important;
        }
      }

      &.isbackdrop {
        opacity: 0.4;
      }

      @media (max-width:break-points.$md) {
        padding: 7px 10px;
      }

      &.settingsMenuItems {
        @media (max-width:break-points.$sm) {
          padding: 1px 10px 1px 48px;
          gap: 6px;
        }
      }

      .text {
        display: inline-block;
        font-size: 15px;
        line-height: 22px;

        &.settingsText {
          @media (max-width:break-points.$sm) {
            font-size: 13px;
            font-weight: 500;
          }
        }
        &.settingsTextdelete{
          @media (max-width:break-points.$sm) {
            font-size: 13px;
            font-weight: 500;
            margin-left: 4px;
          }
        }

        &.active {
          @media (min-width:577px) {
          color: colors.$primaryColor;
          }
        }
      }
    }
  }
}

.hideCredits {
  @media (max-width:break-points.$md) {
    display: none;
  }

  &.settingsCredits {
    @media (max-width:break-points.$sm) {
      display: block;
      padding: 0px 15px 9px 15px;
    }
  }
}

.optionIcon {
  position: absolute;
  right: 0px;
}

.menuSeparator {
  @media (max-width:break-points.$sm) {
    height: 20px;
  }
}
.switchTheme{
  @media (max-width:break-points.$sm) {
  padding: 12px 12px 0px;
  }
}